import upload from "./upload.vue";

import { createVNode, render } from "vue";

/**
    上传组件
    @param {string}      title: "弹窗名",
    @param {string}      url: '上传地址' eg: '/core/sys/user/import'
    @param {string}      TMCode: 下载模板 模板code eg: TM_IMPORT_USER
    @param {string}      fileExt 文件类型 如: ".xls,.xlsx", ".png"
*/

let ctx;
export function szdUpload(title, url, TMCode, fileExt) {
  return new Promise((resolve, reject) => {
    const data = {
      title: title,
      uploadUrl: url,
      TMCode: TMCode,
      accept: fileExt
    };
    console.log("data", data);
    const div = document.createElement("div");
    const vnode = createVNode(upload, {
      updParams: data,
      initByJs: true,
      onUploadSuccess: (returnData, SHCom) => {
        resolve(true);
      },
      onUploadError: handleFlag => {
        reject(data);
        document.body.removeChild(div);
      },
      onCloseDialog: () => {
        document.body.removeChild(div);
      }
    });
    vnode.appContext = ctx;
    document.body.appendChild(div);
    render(vnode, div);
  });

  // return instance
}
const installSzdUpd = function (app) {
  ctx = app._context;
  app.config.globalProperties.szdUpload = szdUpload;
};
export default installSzdUpd;
