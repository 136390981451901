import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import home from '@/views/home.vue'//首页
// import product from '@/views/product.vue'//产品方案
// import proShare from '@/views/pro/pro-share.vue'//财务共享
// import proFund from '@/views/pro/pro-fund.vue'//资金管理
// import proGolden from '@/views/pro/pro-golden.vue'//金税直连
// import proBasic from '@/views/pro/pro-basic.vue'//基础中台
// import caseBox from '@/views/case.vue'//客户案例
// import caseZhongDing from '@/views/case/case-zhongding.vue'//中鼎集团
// import caseShenWei from '@/views/case/case-shenwei.vue'//神威药业
// import caseRunTian from '@/views/case/case-runtian.vue'//江西润田
// import caseJiangXiTieTou from '@/views/case/case-jiangxitietou.vue'//江西铁投
// import caseDaTang from '@/views/case/case-datang.vue'//大唐移动
// import caseZhengDa from '@/views/case/case-zhengda.vue'//正大集团
// import about from '@/views/about.vue'//关于我们



// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch(err => err)
// }

// Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: home
},
{
	path: '/login',
	name: 'login',
	component: () => import('@/views/login/index.vue'),
},
{
	path: '/register',
	name: 'register',
	component: () => import('@/views/login/reg.vue'),
},
{
	path: '/product',
	name: 'product',
	component: () => import('@/views/product.vue')
},
{
	path: '/pro-share',
	name: 'proShare',
	component: () => import('@/views/pro/pro-share.vue')
},
{
	path: '/pro-fund',
	name: 'proFund',
	component: () => import('@/views/pro/pro-fund.vue')
},
{
	path: '/pro-golden',
	name: 'proGolden',
	component: () => import('@/views/pro/pro-golden.vue')
},
{
	path: '/pro-basic',
	name: 'proBasic',
	component: () => import('@/views/pro/pro-basic.vue')
},
{
	path: '/pro-platform',
	name: 'pro-platform',
	component: () => import('@/views/pro/pro-platform.vue')
},
{
	path: '/pro-image',
	name: 'pro-image',
	component: () => import('@/views/pro/pro-image.vue')
},
{
	path: '/pro-archives',
	name: 'pro-archives',
	component: () => import('@/views/pro/pro-archives.vue')
},
{
	path: '/pro-tax',
	name: 'pro-tax',
	component: () => import('@/views/pro/pro-tax.vue')
},
{
	path: '/case',
	name: 'case',
	component: () => import('@/views/case.vue')
},
{
	path: '/case-zhongding',
	name: 'caseZhongDing',
	component: () => import('@/views/case/case-zhongding.vue')
},
{
	path: '/case-shenwei',
	name: 'caseShenWei',
	component: () => import('@/views/case/case-shenwei.vue')
},
{
	path: '/case-runtian',
	name: 'caseRunTian',
	component: () => import('@/views/case/case-runtian.vue')
},
{
	path: '/case-jiangxitietou',
	name: 'caseJiangXiTieTou',
	component: () => import('@/views/case/case-jiangxitietou.vue')
},
{
	path: '/case-datang',
	name: 'caseDaTang',
	component: () => import('@/views/case/case-datang.vue')
},
{
	path: '/case-beiterui',
	name: 'caseBeiterui',
	component: () => import('@/views/case/case-beiterui.vue')
},
{
	path: '/case-fumiaokeji',
	name: 'caseFumiaokeji',
	component: () => import('@/views/case/case-fumiaokeji.vue')
},
{
	path: '/case-hezhongqiche',
	name: 'caseHezhongqiche',
	component: () => import('@/views/case/case-hezhongqiche.vue')
},
{
	path: '/case-liugongjixie',
	name: 'caseLiugongjixie',
	component: () => import('@/views/case/case-liugongjixie.vue')
},
{
	path: '/case-zhonghetongcheng',
	name: 'caseZhonghetongcheng',
	component: () => import('@/views/case/case-zhonghetongcheng.vue')
},
{
	path: '/case-zhengda',
	name: 'caseZhengDa',
	component: () => import('@/views/case/case-zhengda.vue')
},
{
	path: '/about',
	name: 'about',
	component: () => import('@/views/about.vue')
},
{
	path: '/platform',
	name: 'platform',
	redirect: '/platform/home',
	component: () => import('@/views/platform/index.vue'),
	children: [
		{
			path: 'home',
			name: 'platformHome',
			component: () => import('@/views/platform/pages/home/index.vue'),
		},
		{
			path: 'onlineApi',
			name: 'onlineApi',
			redirect: '/platform/onlineApi/overview',
			component: () => import('@/views/platform/pages/onlineApi/index.vue'),
			children: [

				{
					path: 'templateExample',
					name: 'templateExample',
					component: () => import('@/views/platform/pages/onlineApi/pages/templateExample/index.vue'),
				},
				{
					path: 'overview',
					name: 'overview',
					component: () => import('@/views/platform/pages/onlineApi/pages/overview/index.vue'),
				},
				{
					path: 'base',
					name: 'base',
					component: () => import('@/views/platform/pages/onlineApi/pages/base/index.vue'),
					children: [
						{
							path: 'base1',
							name: 'base1',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base1/index.vue'),
						},
						{
							path: 'base2',
							name: 'base2',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base2/index.vue'),
						},
						{
							path: 'base3',
							name: 'base3',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base3/index.vue'),
						},
						{
							path: 'base4',
							name: 'base4',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base4/index.vue'),
						},
						{
							path: 'base5',
							name: 'base5',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base5/index.vue'),
						},
						{
							path: 'base6',
							name: 'base6',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base6/index.vue'),
						},
						{
							path: 'base7',
							name: 'base7',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base7/index.vue'),
						},
						{
							path: 'base8',
							name: 'base8',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base8/index.vue'),
						},
						{
							path: 'base9',
							name: 'base9',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base9/index.vue'),
						},
						{
							path: 'base10',
							name: 'base10',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base10/index.vue'),
						},
						{
							path: 'base11',
							name: 'base11',
							component: () => import('@/views/platform/pages/onlineApi/pages/base/base11/index.vue'),
						},
					]
				},
				{
					path: 'components',
					name: 'components',
					component: () => import('@/views/platform/pages/onlineApi/pages/components/index.vue'),
					children: [
						{
							path: 'installUse',
							name: 'installUse',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/installUse/index.vue'),
						},
						{
							path: 'compFn',
							name: 'compFn',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/compFn/index.vue'),
						},
						{
							path: 'combLabel',
							name: 'combLabel',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/combLabel/index.vue'),
						},
						{
							path: 'compLabel',
							name: 'compLabel',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/compLabel/index.vue'),
						},
						{
							path: 'instruct',
							name: 'instruct',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/instruct/index.vue'),
						},
						{
							path: 'workflowBtn',
							name: 'workflowBtn',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/workflowBtn/index.vue'),
						},
						{
							path: 'workflowFn',
							name: 'workflowFn',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/workflowFn/index.vue'),
						},
						{
							path: 'menuConfig',
							name: 'menuConfig',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/menuConfig/index.vue'),
						},
						{
							path: 'icon',
							name: 'icon',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/icon/index.vue'),
						},
						{
							path: 'file',
							name: 'file',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/file/index.vue'),
						},
						{
							path: 'utils',
							name: 'utils',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/utils/index.vue'),
						},
						{
							path: 'utilsWorkflow',
							name: 'utilsWorkflow',
							component: () => import('@/views/platform/pages/onlineApi/pages/components/utilsWorkflow/index.vue'),
						},
					]
				},
				{
					path: 'it',
					name: 'it',
					component: () => import('@/views/platform/pages/onlineApi/pages/it/index.vue'),
					children: [
						{
							path: 'it1',
							name: 'it1',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it1/index.vue'),
						},
						{
							path: 'it2',
							name: 'it2',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it2/index.vue'),
						},
						{
							path: 'it3',
							name: 'it3',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it3/index.vue'),
						},
						{
							path: 'it4',
							name: 'it4',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it4/index.vue'),
						},
						{
							path: 'it5',
							name: 'it5',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it5/index.vue'),
						},
						{
							path: 'it6',
							name: 'it6',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it6/index.vue'),
						},
						{
							path: 'it7',
							name: 'it7',
							component: () => import('@/views/platform/pages/onlineApi/pages/it/it7/index.vue'),
						},
					]

				},
				{
					path: 'jar',
					name: 'jar',
					component: () => import('@/views/platform/pages/onlineApi/pages/jar/index.vue'),
					redirect: '/platform/onlineApi/jar/jar2',
					children: [
						{
							path: 'jar1',
							name: 'jar1',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar1/index.vue'),
						},
						{
							path: 'jar2',
							name: 'jar2',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar2/index.vue'),
						},
						{
							path: 'jar3',
							name: 'jar3',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar3/index.vue'),
						},
						{
							path: 'jar4',
							name: 'jar4',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar4/index.vue'),
						},
						{
							path: 'jar5',
							name: 'jar5',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar5/index.vue'),
						},
						{
							path: 'jar6',
							name: 'jar6',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar6/index.vue'),
						},
						{
							path: 'jar7',
							name: 'jar7',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar7/index.vue'),
						},
						{
							path: 'jar8',
							name: 'jar8',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar8/index.vue'),
						},
						{
							path: 'jar9',
							name: 'jar9',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar9/index.vue'),
						},
						{
							path: 'jar10',
							name: 'jar10',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar10/index.vue'),
						},
						{
							path: 'jar11',
							name: 'jar11',
							component: () => import('@/views/platform/pages/onlineApi/pages/jar/jar11/index.vue'),
						}
					]
				},
				{
					path: 'quickStart',
					name: 'quickStart',
					component: () => import('@/views/platform/pages/onlineApi/pages/quickStart/index.vue'),
					children: [
						{
							path: 'devDesc',
							name: 'devDesc',
							component: () => import('@/views/platform/pages/onlineApi/pages/quickStart/devDesc/index.vue'),
						},
						{
							path: 'devExper',
							name: 'devExper',
							component: () => import('@/views/platform/pages/onlineApi/pages/quickStart/devExper/index.vue'),
						},
						{
							path: 'localDev',
							name: 'localDev',
							component: () => import('@/views/platform/pages/onlineApi/pages/quickStart/localDev/index.vue'),
						},
					]
				},
			]
		},
		{
			path: 'onlieDemo',
			name: 'onlieDemo',
			component: () => import('@/views/platform/pages/onlieDemo/index.vue'),
		},
		{
			path: 'codeDownload',
			name: 'codeDownload',
			component: () => import('@/views/platform/pages/codeDownload/index.vue'),
		},
		{
			path: 'commercialLicensing',
			name: 'commercialLicensing',
			component: () => import('@/views/platform/pages/commercialLicensing/index.vue'),
		},
		{
		 	path: 'aboutMe',
		 	name: 'aboutMe',
		 	component: () => import('@/views/platform/pages/about/index.vue'),
		 },
		{
			path: 'appMarket',
			name: 'appMarket',
			component: () => import('@/views/platform/pages/appMarket/index.vue'),
		},
		{
			path: 'manage',
			name: 'manage',
			component: () => import('@/views/platform/pages/manage/index.vue'),
		},
	]
},

]

const router = createRouter({
	history: createWebHistory(),

	routes,
})

export default router
