<template>
	<el-dialog :title="dialogParams.title" :visible.sync="dialogParams.open" append-to-body
		:close-on-click-modal="false"  v-szd-dialog="[1200,500]" class="app-dialog-container">
		<div style="width: 100%;height:100%;">
			<iframe :src="src" id="web_wf_dy_design" frameborder="0" style="width: 100%;height: 100%;" scrolling="auto" />
		</div>
	</el-dialog>
</template>

<script>
	import store from '@/store/index' //全局引入vuex
	export default {
		store,
		data() {
			return {
				dialogParams: {
					title: "配置动态流程",
					open: false
				},
				src: '',
			}
		},
		methods: {
			//接受参数
			acceptData(wfId, userId) {
				const params = {
					wfId: wfId
				}
				if (userId) {
					params.userId = userId
				}
				this.src = this.szdTLGetUrl('CORE_WF_10A', params)
				this.dialogParams.open = true;
			},
		}
	}
</script>
