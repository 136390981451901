<template>
	<!-- 通用分页组件 -->
	<div v-show="total>0" class="pagination-container">
		<el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout"
		 :page-sizes="pageSizes" :total="total" v-bind="$attrs" @size-change="handleSizeChange" @current-change="handleCurrentChange"
		 :class="position=='R'?'pagination-container-right':'pagination-container-left'" small />
	</div>
</template>

<script>
	export default {
		name: 'Pagination',
		props: {
			//定位
			position: {
				type: String,
				default () {
					return 'R'
				}
			},
			//总条数
			total: {
				required: true,
				type: Number
			},
			//当前第几页
			page: {
				type: Number,
				default: 1
			},
			//每页多少条
			limit: {
				type: Number,
				default: 20
			},
			//页大小设置
			pageSizes: {
				type: Array,
				default () {
					return [20, 50, 200, 500]
				}
			},
			//样式
			layout: {
				type: String,
				default: 'total, sizes, prev, pager, next, jumper'
			},
			//背景色
			background: {
				type: Boolean,
				default: true
			},
			//是否滚到到头位置
			autoScroll: {
				type: Boolean,
				default: true
			},
		},
		computed: {
			currentPage: {
				get() {
					return this.page
				},
				set(val) {
					this.$emit('update:page', val)
				}
			},
			pageSize: {
				get() {
					return this.limit
				},
				set(val) {
					this.$emit('update:limit', val)
				}
			}
		},
		methods: {
			handleSizeChange(val) {
				this.$emit('pagination', {
					page: this.currentPage,
					limit: val
				})
				if (this.autoScroll) {
					this.scrollTo()
				}
			},
			handleCurrentChange(val) {
				this.$emit('pagination', {
					page: val,
					limit: this.pageSize
				})
				if (this.autoScroll) {
					this.scrollTo()
				}
			},
			scrollTo() {
				const els = document.querySelectorAll('.el-table__body-wrapper')
				els.forEach((item) => {
					item.scrollTop = 0
				})
			}
		}
	}
</script>
<style scoped>
	/* 分页 */
	.pagination-container {
		position: relative;
		height: 50px;
		padding: 10px;
		box-sizing: border-box;

		/* .el-pagination .el-select .el-input .el-input__inner {
			border-radius: 1px;
		} */

		/* .el-input__inner {
			height: 26px !important;
		} */
	}

	.pagination-container .el-pagination {
		height: 30px;
		position: absolute;
	}

	.pagination-container-right {
		right: 0;
	}

	.pagination-container-left {
		left: 0;
	}
</style>
