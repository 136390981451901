<template>
  <!-- 业务主体信息 -->
  <el-dialog
    title="其它信息-业务主体"
    :visible.sync="dg.open"
    append-to-body
    :close-on-click-modal="false"
    v-szd-dialog="[1000, 550]"
    class="app-dialog-container">
    <el-form class="app-el-form">
      <el-row>
        <el-col :span="12">
          <el-form-item label="业务单号">
            <el-col :span="12">
              <el-input v-model="objData.wfBussDocId" disabled />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务ID">
            <el-col :span="12">
              <el-input v-model="objData.wfBussId" disabled />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="公司代码">
            <el-col :span="12">
              <el-input v-model="objData.wfCompanyCode" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfCompanyName" />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="成本中心">
            <el-col :span="12">
              <el-input v-model="objData.wfCostCenterCode" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfCostCenterName" />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="业务类型">
            <el-col :span="12">
              <el-input v-model="objData.wfbstp" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfbstpName" />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="动态流程">
            <el-col :span="12">
              <el-input v-model="objData.wfDyNo" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfDyName" />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="业务标题">
            <el-input v-model="objData.wfTitle" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单据状态">
            <el-col :span="12">
              <szd-select v-model="objData.wfStatus" dict="CORE_WF_DOST" disabled />
            </el-col>
            <el-col :span="12" class="main-left10px">
              <el-checkbox v-model="objData.wfRejectStatus" label="驳回" disabled />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="申请金额">
            <el-col :span="12">
              <el-input-number v-model="objData.wfAmtApply" :controls="false" :precision="2" disabled />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="申请税额">
            <el-col :span="12">
              <el-input-number v-model="objData.wfAmtApplyTax" :controls="false" :precision="2" disabled />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="实际金额">
            <el-col :span="12">
              <el-input-number v-model="objData.wfAmtActual" :controls="false" :precision="2" disabled />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际税额">
            <el-col :span="12">
              <el-input-number v-model="objData.wfAmtApplyTax" :controls="false" :precision="2" disabled />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="付款单号">
            <el-col :span="12">
              <el-input v-model="objData.wfSapPayDoc" disabled />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="辅助单号">
            <el-col :span="12">
              <el-input v-model="objData.wfSapOtherDoc" disabled />
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="会计凭证">
            <el-col :span="12">
              <el-input v-model="objData.wfSapAccDoc" disabled />
            </el-col>
            <el-col :span="12">
              <el-col :span="12">
                <div class="main-form-lable">财年</div>
              </el-col>
              <el-col :span="12">
                <el-input v-model="objData.wfSapAccYear" disabled />
              </el-col>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="冲销凭证">
            <el-col :span="12">
              <el-input v-model="objData.wfSapAccDocOff" disabled />
            </el-col>
            <el-col :span="12">
              <el-col :span="12">
                <div class="main-form-lable">冲销财年</div>
              </el-col>
              <el-col :span="12">
                <el-input v-model="objData.wfSapAccYearOff" disabled />
              </el-col>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="制单人">
            <el-col :span="12">
              <el-input v-model="objData.wfCreateBy" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfCreateByName" />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="制单时间">
            <el-input v-model="objData.wfCreateTime" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="提交人">
            <el-col :span="12">
              <el-input v-model="objData.wfCommitBy" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfCommitByName" />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="提交时间">
            <el-input v-model="objData.wfCommitTime" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="更改人">
            <el-col :span="12">
              <el-input v-model="objData.wfUpdateBy" disabled />
            </el-col>
            <el-col :span="12">
              <szd-desc v-model="objData.wfUpdateByName" />
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="更改时间">
            <el-input v-model="objData.wfUpdateTime" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="main-textarea">
            <div style="width: 12.5%" class="main-textarea-lable">备注</div>
            <div style="width: 87.5%" class="main-textarea-content">
              <el-input type="textarea" v-model="objData.wfRemark" maxlength="150" rows="3" show-word-limit class="main-textarea-input" disabled />
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="logInfo" v-if="!!objData.wfBussId">修改日志</el-button>
      <el-button type="primary" @click="colseForm">关闭</el-button>
    </div>
    <!-- 信息维护 -->
    <wfBussInfoClog ref="wfBussInfoClog" />
  </el-dialog>
</template>

<script>
  import wfBussInfoClog from "./wfBussInfoClog.vue";
  export default {
    components: {
      wfBussInfoClog
    },
    data() {
      return {
        dg: {
          open: false
        },
        objData: {
          wfBussDocId: ""
        }
      };
    },
    methods: {
      //接受参数
      acceptData(infoParams) {
        this.dg.open = true;
        this.objData = infoParams;
      },
      //关闭窗口
      colseForm() {
        this.dg.open = false;
      },
      //修改日期
      logInfo() {
        this.szdTLIfrDialog("CORE_MON_25A", {
          bussId: this.objData.wfBussId,
          width: "75%",
          height: "65%"
        });
      }
    }
  };
</script>
