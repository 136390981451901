// import iframeDialog from "./iframeDialog.vue";

import { createVNode, render } from "vue";

// 引入信息提示组件
import customMessage from "./iframeDialog.vue";
export function szdTLIfrDialog(routerCode, params = {}) {
  return new Promise((resolve, reject) => {
    const ifrmUrl = "https://www.szdsoft.cn/";
    const query = "";
    console.log("query=====", query);
    let w = "",
      h = "";
    if (params.width) {
      w = params.width;
      // delete params.width
    }
    if (params.height) {
      h = params.height;
      // delete params.height
    }
    // 如果没有传宽高则取路由信息中的宽高
    if (!params.width && query.winWidth) {
      w = query.winWidth ? query.winWidth : "95%";
    }

    if (!params.height && query.winHeight) {
      h = query.winHeight ? query.winHeight : "85%";
    }

    const title = params.title || query.routerTitle || "";

    const vnode = createVNode(customMessage, {
      title,
      src: ifrmUrl,
      width: w ? w : "95%",
      height: h ? h : "85%"
    });
    // 3. 准备一个DOM容器
    // 4. 把虚拟节点渲染DOM容器中
    const div = document.createElement("div");
    // 添加到body上
    document.body.appendChild(div);
    render(vnode, div);
    resolve(true);
  });

  // return instance
}
