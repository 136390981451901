import { nextTick } from "vue";

export default {
  updated(el, binding, vnode) {
    nextTick(() => {
      // 获取当前元素的父节点
      let content = binding.instance.$el;
      // 获取父节点的高度
      let cHeight = content.clientHeight;
      // 获取元素父节点距离顶部的距离
      let cOffsetTop = binding.instance.$parent.$el.offsetTop;
      // 获取当前节点的高度
      let elHeight = el.clientHeight;
      // 去除el高度外的其他高度
      let oHeight = cHeight - elHeight;
      el.style.height = `calc(100vh - ${oHeight + cOffsetTop + "px"})`;
    });
  },
};
