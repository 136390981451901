//删除确认提示
import { szdSh } from "../mainSerchHelp";

export function szdPopDel(msgText, catchIf) {
  return new Promise((resolve, reject) => {
    let msg = "数据将被删除，请确认?";
    if (msgText != undefined && msgText != "") {
      msg = msgText;
    }
    this.$confirm(msg, "警告", {
      distinguishCancelAndClose: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        resolve(true);
      })
      .catch(action => {
        if (catchIf == "Y" && action === "cancel") {
          reject(false);
        }
      });
  });
}
//保存确认提示
export function szdPopSave(msgText, catchIf) {
  return new Promise((resolve, reject) => {
    let msg = "数据保存，请确认?";
    if (msgText != undefined && msgText != "") {
      msg = msgText;
    }
    this.$confirm(msg, "警告", {
      distinguishCancelAndClose: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
      .then(() => {
        resolve(true);
      })
      .catch(action => {
        if (catchIf == "Y" && action === "cancel") {
          reject(false);
        }
      });
  });
}
//保存确认提示
export function szdPop(msgText, catchIf) {
  return new Promise((resolve, reject) => {
    console.log(this.$confirm);
    this.$confirm(msgText, "警告", {
      distinguishCancelAndClose: true,
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
      customClass: "szd-msg-box"
    })
      .then(() => {
        resolve(true);
      })
      .catch(action => {
        if (catchIf == "Y" && action === "cancel") {
          reject(false);
        }
      });
  });
}

const installPopup = app => {
  app.config.globalProperties.szdPopDel = szdPopDel;
  app.config.globalProperties.szdPopSave = szdPopSave;
  app.config.globalProperties.szdPop = szdPop;
};
export default installPopup;
