<template>
  <template v-if="_checkboxGroup">
    <el-checkbox
      v-for="item in _modelValue"
      v-model="item.value"
      :label="item.label"
      :key="item.key ? item.key : undefined"
      :disabled="item.disabled ? item.disabled : false"
      @change="handleChange"
      :szdfst="szdfst" />
  </template>
  <template v-else>
    <el-checkbox v-model="_modelValue" :label="boxLabel" :disabled="disabled" @change="handleChange" :szdfst="szdfst" />
  </template>
</template>
<script setup>
  import { defineProps, defineEmits, computed } from "vue";

  const props = defineProps({
    //数组内结构 [{value:'',label:'',key:'',disabled:''}]
    modelValue: {
      type: [String, Number, Boolean, Array]
    },
    boxLabel: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    szdfst: {
      type: String
    }
  });
  //更新数据对象返回值
  const emit = defineEmits(["update:modelValue", "change"]);
  //检查是否未数组
  const _checkboxGroup = computed(() => {
    return Array.isArray(props.modelValue);
  });
  //值数据
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
</script>
