import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; //全局引入vuex

import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/style/animate.css";
import installComponent from "@/modules/components/mainIndex";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import $bus from "./emitter";
import RightTitle from "./views/platform/pages/onlineApi/components/rightPage.vue";
import CustomTable from "./views/platform/pages/onlineApi/components/table.vue";
import CustomCode from "./views/platform/pages/onlineApi/components/code.vue";
import CustomImg from "./views/platform/pages/onlineApi/components/customImg.vue";
import OnlineConsulttation from "./components/onlineConsultation.vue";
// import pagination from "./components/pagination/pagination.vue";
import ComponentTemplate from "@/views/platform/pages/onlineApi/components/componentTemplate.vue";
import installDirective from "@/modules/directives/index";

//全局引用搜索帮助
import installSearchHelp from "@/modules/components/mainSerchHelp";
import installPopup from "@/modules/components/popup/popup";
import installSzdUpd from "@/modules/components/upload/upload";
import installDateFn from "@/common/tools/commonDate";
// import "@/modules/components/mainSerchHelpFix.js";
// import "@/modules/components/popup/popup.js";
// import "@/modules/components/upload/upload.js";
// import "@/modules/components/loading/loading.js";
// import "@/modules/components/mainMsgBox.js";
// import "@/modules/components/iframeDialog/iframeDialog.js";
// import "@/modules/components/iframeDialog/iframeDialog.js";
//
// import desc from "@/modules/components/desc/desc.vue";
//
// import selectCustom from "@/modules/components/select/selectCustom.vue";
// import radio from "@/modules/components/radio/radio.vue";
// import area from "@/modules/components/area/area.vue";
// import pagi from "@/modules/components/pagination/pagination.vue";
// import footStatus from "@/modules/components/footStatus/footStatus.vue";
// import richTxt from "@/modules/components/editor/editor.vue";
// import wfButton from "@/modules/wf/components/wfButton/wfButton.vue";
// Vue.component("szd-radio", radio);
// Vue.component("szd-desc", desc);
// Vue.component("szd-select-custom", selectCustom);
// Vue.component("szd-area", area);
// Vue.component("szd-pagination", pagi);
// Vue.component("szd-foot-status", footStatus);
// Vue.component("szd-wf-button", wfButton);
// Vue.component("szd-editor", richTxt);
//
// /*指令*/
// import "@/modules/directives/index.js";
import "@/mock/index.js";

const req = require.context("@/assets/icons/svg", false, /.svg$/);
req.keys().forEach(svg => {
  req(svg);
});
const app = createApp(App);
app.component("OnlineConsulttation", OnlineConsulttation);
app.config.globalProperties.$bus = $bus;

import SvgIcon from "@/components/svgIcon/svgIconView.vue";
app.component("svg-icon", SvgIcon);
app.component("CustomCode", CustomCode);
app.component("ComponentTemplate", ComponentTemplate);
app.component("RightTitle", RightTitle);
app.component("CustomTable", CustomTable);
app.component("CustomImg", CustomImg);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
installSzdUpd(app);
installSearchHelp(app);
installPopup(app);
installComponent(app);
installDirective(app);
installDateFn(app);

//全局js方法区
import installCommonTool from "./common/tools";
//监听路由跳转
import "./common/utils/permission";

app.component("OnlineConsulttation", OnlineConsulttation);
installCommonTool(app);
app.use(store).use(router).use(ElementPlus).mount("#app");
