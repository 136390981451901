<template>
  <el-form-item :label="label" :label-width="labelWidth" :prop="prop" :required="required" :szdfst="szdfst">
    <template v-if="helpTip" #label>
      <div>
        <span>{{ label }}</span>
        <el-icon class="main-help-tip">
          <QuestionFilled @click.prevent.stop="handleHelpTip" />
        </el-icon>
      </div>
    </template>
    <template v-if="descSpan > 0 && descSpan < 24">
      <el-row style="width: 100%">
        <el-col :span="24 - descSpan">
          <szd-time v-bind="$attrs" v-model="_modelValue" @change="handleChange" @focus="handleFocus" @blur="handleBlur" />
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <szd-time v-bind="$attrs" v-model="_modelValue" @change="handleChange" @focus="handleFocus" @blur="handleBlur" />
    </template>
  </el-form-item>
</template>

<script setup>
  import { defineProps, defineEmits, computed, getCurrentInstance } from "vue";

  const { proxy } = getCurrentInstance();
  const props = defineProps({
    //输入框所需的参数
    modelValue: {
      type: [String, Number, Date, Array]
    },
    szdfst: {
      type: String
    },
    //form-item所需的参数
    label: {
      type: String,
      default: ""
    },
    prop: {
      type: String
    },
    labelWidth: {
      type: [Number, String]
    },
    required: {
      type: Boolean,
      default: false
    },
    descSpan: {
      type: Number,
      default: 0
    },
    helpTip: {
      type: String
    }
  });
  const emit = defineEmits(["update:modelValue", "change", "focus", "blur"]);
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
  //在 Input 获得焦点时触发
  const handleFocus = e => {
    emit("focus", e);
  };
  //在 Input 失去焦点时触发
  const handleBlur = e => {
    emit("blur", e);
  };
  //显示文字帮助事件
  const handleHelpTip = () => {
    proxy.szdTLHelpDoc(props.helpTip);
  };
</script>
