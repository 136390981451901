// 权限处理
import store from "@/store";
import { translateLang } from "@/common/tools/commonTool";

export default {
  // 按钮操作权限处理 v-szd-but="'system:user:add'"
  mounted(el, binding, vnode) {
    const { value } = binding;
    const permissions = store.state.frmUser.authButton;
    if (value) {
      if (!(permissions.includes("*:*:*") || permissions.includes(value))) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(translateLang(`请设置操作权限标签值`));
    }
  }
};
