import { createApp } from "vue";
//消息弹窗
function szdMsgBox(parms) {
  return new Promise((resolve, reject) => {
    if (parms != undefined) {
      szdMsgBoxShow(parms)
        .then(res => {})
        .catch(() => {
          reject(false);
        });
    }
  });
}
// 消息弹窗
function szdMsgBoxShow(parms) {
  const _import = import(`./msgBox/msgBox.vue`).then(res => {
    const mountDiv = document.createElement("div");
    const constructor = createApp(res.default, {
      close: res => {
        constructor.unmount(mountDiv);
        document.body.removeChild(mountDiv);
      }
    });
    const instance = constructor.mount(mountDiv);
    instance.objData = parms;
    document.body.appendChild(mountDiv);
  });
  return _import;
}

export default szdMsgBox;
