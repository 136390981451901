<template>
  <el-input-number
    v-model="_modelValue"
    :placeholder="placeholder"
    :max="max"
    :min="min"
    :precision="precision"
    :step="step"
    :readonly="readonly"
    :disabled="disabled"
    @change="handleChange"
    @focus="handleFocus"
    @blur="handleBlur"
    :szdfst="szdfst"
    controls-position="right" />
</template>
<script setup>
  import { defineProps, defineEmits, computed } from "vue";

  const props = defineProps({
    modelValue: {
      type: Number
    },
    placeholder: {
      type: String
    },
    max: {
      type: Number
    },
    min: {
      type: Number
    },
    precision: {
      type: Number
    },
    step: {
      type: Number,
      default: 1
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    szdfst: {
      type: String
    }
  });
  //定义事件
  const emit = defineEmits(["update:modelValue", "change", "focus", "blur"]);
  //值数据
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
  //在 Input 获得焦点时触发
  const handleFocus = e => {
    emit("focus", e);
  };
  //在 Input 失去焦点时触发
  const handleBlur = e => {
    emit("blur", e);
  };
</script>
