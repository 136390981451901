<template>
  <!-- 通用导入Excel组件 -->
  <el-dialog
    :title="uploadParams.title"
    v-model="dialogParams.open"
    append-to-body
    class="app-dialog-container"
    @close="handleColseForm"
    align-center
    draggable="">
    <div v-szd-dialog="[400, 380]"></div>
    <el-upload
      ref="upload"
      :limit="uploadParams.limit"
      :accept="uploadParams.accept"
      :headers="conParams.headers"
      :action="uploadParams.uploadUrl"
      :auto-upload="false"
      :disabled="uploadParams.disabled"
      :on-progress="handleFileUploadProgress"
      :on-success="handleFileSuccess"
      :on-error="handleFileError"
      drag>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        {{ $t("将文件拖到此处，或") }}
        <em>{{ $t("点击上传") }}</em>
      </div>
      <div v-if="uploadParams.acceptTxt != ''" class="el-upload__tip" style="color: red" slot="tip">
        提示：仅允许导入{{ uploadParams.acceptTxt }}格式文件！
      </div>
    </el-upload>

    <template #footer>
      <el-button type="primary" @click="handleDownloadTM" v-if="uploadParams.TMCode != ''">下载模板</el-button>
      <el-button type="primary" @click="colseForm">取 消</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { downloadTemplate } from "../api/upload.js";
  export default {
    props: {
      updParams: {
        type: Object,
        default: () => ({})
      },
      initByJs: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dialogParams: {
          open: false,
          retcode: "" //返回码，S成功(刷新) Q或空取消操作 E 操作错误
        },
        conParams: {
          headers: {
            // 设置上传的请求头部
            Authorization: "Bearer "
          },
          rootUrl: process.env.VUE_APP_BASE_API // 上传的根地址
        },
        uploadParams: {
          title: "上传文件",
          limit: 1, //最大上传文件数
          accept: "", //上传文件类型
          acceptTxt: "",
          disabled: false,
          uploadUrl: "", //上传的地址
          emitMethod: "", //回调方法
          TMCode: ""
        }
      };
    },
    mounted() {
      if (this.initByJs) {
        console.log("qqwqwqwqwqw");
        this.acceptData(this.updParams);
      }
    },
    methods: {
      //接受参数
      acceptData(infoParam) {
        this.initData();
        // 上传数据参数
        if (infoParam.uploadUrl == undefined || infoParam.uploadUrl == "") {
          this.$message.error(this.$t("上传地址参数不能为空"));
          return;
        }
        if (infoParam.title != undefined && infoParam.title != "") {
          this.uploadParams.title = infoParam.title;
        }
        if (infoParam.limit != undefined && infoParam.limit != "") {
          this.uploadParams.limit = infoParam.limit;
        }
        if (infoParam.accept != undefined && infoParam.accept != "") {
          this.uploadParams.accept = infoParam.accept;
          this.uploadParams.acceptTxt = this.uploadParams.accept;
        }
        this.uploadParams.uploadUrl = this.conParams.rootUrl + infoParam.uploadUrl;
        // if (infoParam.emitMethod != undefined && infoParam.emitMethod != '') {
        // 	this.uploadParams.emitMethod = infoParam.emitMethod
        // }
        if (infoParam.TMCode != undefined && infoParam.TMCode != "") {
          this.uploadParams.TMCode = infoParam.TMCode;
        }
        this.dialogParams.open = true;
      },
      // 提交上传文件
      submitForm() {
        this.$refs.upload.submit();
      },
      // 文件上传中处理
      handleFileUploadProgress(event, file, fileList) {
        this.uploadParams.disabled = true;
      },
      // 文件上传成功
      handleFileSuccess(response, file, fileList) {
        this.uploadParams.disabled = false;
        if (response.code == 200) {
          this.$refs.upload.clearFiles();
          this.dialogParams.retcode = "S";
          this.$message.success(response.msg);
          this.colseForm();
          this.$emit("uploadSuccess");
        } else {
          this.$emit("uploadError", response.msg);
          const msg = response.msg.replaceAll("\n", "<br/>");
          this.$message.error({
            message: msg,
            dangerouslyUseHTMLString: true
          });
        }
      },
      // 文件上传失败
      handleFileError(err, file, fileList) {
        this.uploadParams.disabled = false;
        this.$message.error(this.$t("上传文件失败，请检查文件"));
        this.$emit("uploadError", err);
      },
      //关闭
      colseForm() {
        this.dialogParams.open = false;
        this.$emit("CloseDialog");
      },
      handleColseForm() {
        // this.$emit('closeUpload')
        this.$refs.upload.clearFiles();
        // // 调用父页面
        // if (this.uploadParams.emitMethod != undefined && this.uploadParams.emitMethod != '') {
        // 	this.$emit(this.uploadParams.emitMethod, this.dialogParams);
        // }
      },
      // 下载模板
      handleDownloadTM() {
        downloadTemplate(this.uploadParams.TMCode).then(response => {
          // 利用a标签自定义下载文件名
          const link = document.createElement("a");
          // 创建Blob对象，设置文件类型
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
          });
          let objectUrl = URL.createObjectURL(blob); // 创建URL
          link.href = objectUrl;
          link.download = this.szdTLFmtDtA("", "YYYYMMDDHHmmssSSS"); // 自定义文件名
          link.click(); // 下载文件
          URL.revokeObjectURL(objectUrl); // 释放内存
          this.$message.success(this.$t("导出文件成功"));
        });
      },
      //初始化数据
      initData() {
        this.dialogParams.retcode = "";
        this.uploadParams = {
          title: "上传文件",
          limit: 1, //最大上传文件数
          accept: "", //上传文件类型
          acceptTxt: "",
          disabled: false,
          uploadUrl: "", //上传的地址
          emitMethod: "", //回调方法
          TMCode: ""
        };
      }
    }
  };
</script>
