// 大写组件
// 输入框验证, 改成输入时校验
// 输入限制和提示都有, 通过参数决定用哪个方式 errorType
// 类型改成单字母
// 自定义校验规则, 自定义描述信息 reg errMsg
// 提示框换掉
import { ElMessage, ElMessageBox } from "element-plus";
function repalceErrStr(reg, str) {
  const strArr = str.split("");
  for (let i = 0; i < strArr.length; i++) {
    if (!reg.test(strArr[i])) {
      strArr[i] = "";
    }
  }
  return strArr.join("");
}

export default {
  mounted(el, binding, vnode, oldVnode) {
    console.log("binding", binding.value);
    // 源数据
    const source = binding.value[0];
    // 源数据key
    const key = binding.value[1];
    // 验证类型
    const _valiType = binding.value[2];
    // 错误后操作方式, 1-弹窗提示 2-输入限制
    const errType = binding.value[3];
    // 自定义验证规则
    const pReg = binding.value[4];
    // 自定义错误信息
    const pErrMsg = binding.value[5];

    // 外部使用A-X, 内部映射可读性强
    const valiTypeMap = {
      A: "upper",
      B: "lower",
      C: "num",
      D: "alp",
      E: "numAlp",
    };

    console.log("_valiType", _valiType);
    const valiType = valiTypeMap[_valiType];
    console.log("valiType", valiType);

    const inp = el.querySelector("input") || el;
    let regs = {
      // 只能输入、数字、字母、下划线、中划线, 且字母只能大写
      upper: /^[A-Z0-9_-]+$/,
      // 只能输入、数字、字母、下划线、中划线, 且字母只能小写
      lower: /^[a-z0-9_-]+$/,
      // 只能输入数字
      num: /^[-]?\d*(\.\d*)?$/,
      // num: /^-?\d+(?:\.\d*)?$/,

      // 只能输入字母
      alp: /^[A-Za-z]+$/,
      // 只能输入数字或字母
      numAlp: /^[0-9A-Za-z]+$/,
    };

    // 添加自定义规则
    if (pReg) {
      regs.pReg = pReg;
    }

    const msg = {
      upper: "只能输入、数字、字母、下划线、中划线, 且字母只能大写",
      lower: "只能输入、数字、字母、下划线、中划线, 且字母只能小写",
      num: "只能输入数字",
      alp: "只能输入字母",
      numAlp: "只能输入数字或字母",
    };
    // 添加自定义错误信息
    if (pErrMsg) {
      msg.pErrMsg = pErrMsg;
    }

    if (errType == 2) {
      // 以弹窗方式提示错误
      inp.addEventListener("blur", () => {
        if (pReg) {
          const valid = regs.pReg.test(inp.value);
          if (!valid) {
            ElMessage.error(msg.pErrMsg);
            // vnode.context.$set(source, key, "");
            source[key] = "";
          }

          return;
        }

        const valid = regs[valiType].test(inp.value);
        if (!valid) {
          ElMessage.error(msg[valiType]);
          // vnode.context.$set(source, key, "");
          source[key] = "";
        } else {
          // const typeHandle = {
          // 	upper: () => {
          // 		return inp.value.toString().toUpperCase()
          // 	},
          // 	lower: () => {
          // 		return inp.value.toString().toLowerCase()
          // 	},
          // 	num: () => {
          // 		return inp.value
          // 	},
          // 	alp: () => {
          // 		return inp.value
          // 	},
          // 	numAlp: () => {
          // 		return inp.value
          // 	}
          // }
          // Vue.set(source, key, typeHandle[valiType]())
        }
      });
    } else {
      // 输入限制,纠正
      inp.addEventListener("input", (e) => {
        // 自定义规则单独处理
        if (pReg) {
          const valid = regs.pReg.test(inp.value);
          if (!valid) {
            ElMessage.error(msg.pErrMsg);
            let _vlaue = repalceErrStr(regs.pReg, inp.value);
            console.log("_vlaue", _vlaue);
            // vnode.context.$set(source, key, _vlaue);
            source[key] = _vlaue;
          }

          return;
        }

        const valid = regs[valiType].test(inp.value);
        if (!valid) {
          ElMessage.error(msg[valiType]);
          let _vlaue = repalceErrStr(regs[valiType], inp.value);

          const typeHandle = {
            upper: () => {
              _vlaue = _vlaue.toUpperCase();
            },
            lower: () => {
              _vlaue = _vlaue.toLowerCase();
            },
            num: () => {
              // const vlaueArr = inp.value.split('.')
              // _vlaue = `${vlaueArr[0]}.${vlaueArr[1]}`
            },
            alp: () => {
              // vnode.context.$set(source, key, _vlaue)
            },
            numAlp: () => {
              // vnode.context.$set(source, key, _vlaue)
            },
          };

          if (pReg) {
            typeHandle.pReg = () => {
              // vnode.context.$set(source, key, _vlaue);
              source[key] = _vlaue;
            };
          }

          typeHandle[valiType]();
          // vnode.context.$set(source, key, _vlaue);
          source[key] = _vlaue;
        }
      });
    }
  },
};
