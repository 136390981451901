import searchHelpCommon from "./searchHelp/searchHelpCommon.vue";
import { createVNode, render } from "vue";

let ctx;
let gRetShComm = {
  defFieldId: ""
};
//搜索帮助弹出框
// baseField[基础字段] 必填
// setType[类型]  可选默认单选 [1单选 2多选 6多选用逗号分隔赋值]
// setData[更改的数据(对象或数组)] 必填
// setMapping[映射关系] 如：['字段(目标)-字段(源)','字段(目标和源相同)'] 必填
// rowIndex [多行赋值时的行号]（行赋值必传）如：scope.$index 可选
// selectData[查询条件] 如：[['字段',值],['字段',值]] 可选
// disabled[是否可以赋值] true 不可以赋值
// <@click="szdSh('ROLE',1,objData.roleList,['roleId','roleName'],scope.$index,[['proID',qeryFrom.proID]],disabled)"/>
export function szdSh(baseField, setType, setData, setMapping, rowIndex, selectData, disabled) {
  return new Promise((resolve, reject) => {
    szdShShow(baseField, selectData, setType).then(retData => {
      if (disabled !== true && retData.length > 0) {
        //映射关系转化为数组
        let arrMapping = [];
        for (let i = 0; i < setMapping.length; i++) {
          let temp_arr = setMapping[i].split("-");
          if (temp_arr.length > 1 && temp_arr[1]) {
          } else {
            temp_arr[1] = temp_arr[0];
          }
          //设置缺省值字段
          if (temp_arr[1] === "*") {
            temp_arr[1] = gRetShComm.defFieldId;
          }
          arrMapping.push(temp_arr);
        }
        //赋值
        if (setType === 2) {
          //多选赋值
          szdSh2(setData, arrMapping, retData, rowIndex);
        } else if (setType === 6) {
          //多选用逗号分隔赋值
          szdSh6(setData, arrMapping, retData);
        } else {
          //单选赋值
          szdSh1(setData, arrMapping, retData);
        }
        resolve(retData);
      } else {
        resolve([]);
      }
    });
  });
}

//调用搜索帮助窗口
export function szdShShow(baseField, selectData, setType) {
  return new Promise((resolve, reject) => {
    gRetShComm = { defFieldId: "" };
    const div = document.createElement("div");
    let multipleChoice = 0;
    if (setType === 2 || setType === 6) {
      multipleChoice = 2;
    } else {
      multipleChoice = 1;
    }
    const vnode = createVNode(searchHelpCommon, {
      baseField,
      multipleChoice,
      selectData,
      onHanldSH: (retData, retShComm) => {
        gRetShComm = retShComm;
        resolve(retData);
      },
      onCloseDialog: type => {
        if (type !== "okay") {
          resolve([]);
        }
        document.body.removeChild(div);
      }
    });
    vnode.appContext = ctx;
    document.body.appendChild(div);
    render(vnode, div);
  });
}

//单选赋值
function szdSh1(setData, arrMapping, res) {
  for (let i = 0; i < arrMapping.length; i++) {
    setData[arrMapping[i][0]] = res[0][arrMapping[i][1]];
  }
}

//2 多选赋值
function szdSh2(setData, arrMapping, res, rowIndex) {
  //当前点击行赋值
  let rowData = setData[rowIndex];
  for (let i = 0; i < arrMapping.length; i++) {
    rowData[arrMapping[i][0]] = res[0][arrMapping[i][1]];
  }
  //多行赋值，克隆当前点击行，同时清空值
  if (res.length > 1) {
    for (let i = 1; i < res.length; i++) {
      let tempRow = {};
      for (let key in rowData) {
        tempRow[key] = "";
      }
      for (let j = 0; j < arrMapping.length; j++) {
        tempRow[arrMapping[j][0]] = res[i][arrMapping[j][1]];
      }
      setData.push(tempRow);
    }
  }
}

//6 多选赋值以逗号分隔
function szdSh6(setData, arrMapping, res) {
  let jsonTemp = {};
  for (let i = 0; i < res.length; i++) {
    for (let j = 0; j < arrMapping.length; j++) {
      if (jsonTemp[arrMapping[j][0]]) {
        jsonTemp[arrMapping[j][0]] = res[i][arrMapping[j][1]];
      } else {
        jsonTemp[arrMapping[j][0]] = jsonTemp[arrMapping[j][0]] + "," + res[i][arrMapping[j][1]];
      }
    }
  }
  for (let j = 0; j < arrMapping.length; j++) {
    setData[arrMapping[j][0]] = jsonTemp[arrMapping[j][0]];
  }
}

const installSearchHelp = function (app) {
  ctx = app._context;
  app.config.globalProperties.szdSh = szdSh;
  app.config.globalProperties.szdShShow = szdShShow;
};
export default installSearchHelp;
