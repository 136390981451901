import szdLoading from "./loading/loading.js";
import szdMsgBox from "@/modules/components/mainMsgBox";
import { szdTLIfrDialog } from "@/modules/components/iframeDialog/iframeDialog";
import { getToken } from "@/common/utils/authToken";
import select from "@/modules/components/select/select.vue";
import radio from "@/modules/components/radio/radio.vue";
import area from "@/modules/components/area/area.vue";
import pagination from "@/components/pagination/pagination.vue";
import footStatus from "@/modules/components/footStatus/footStatus.vue";
import wfButton from "@/modules/wf/components/wfButton/wfButton.vue";
import time from "./time/time.vue";
import date from "@/modules/components/date/date.vue";
import inputNumber from "@/modules/components/inputNumber/inputNumber.vue";
import checkbox from "@/modules/components/checkbox/checkbox.vue";
import textarea from "@/modules/components/textarea/textarea.vue";
import tabsWrap from "@/modules/components/tabsWrap/tabsWrap.vue";
import seniorSch from "./seniorSearch/seniorSearch.vue";
import input from "@/modules/components/input/input.vue";
import { szdCheck } from "@/common/tools/validate";
import inputCtrl from "@/modules/components/input/inputCtrl.vue";
import inputFormItem from "@/modules/components/input/inputFormItem.vue";
import inputFormItemCol from "@/modules/components/input/inputFormItemCol.vue";
import inputNumberCtrl from "@/modules/components/inputNumber/inputNumberCtrl.vue";
import inputNumberFormItem from "@/modules/components/inputNumber/inputNumberFormItem.vue";
import inputNumberFormItemCol from "@/modules/components/inputNumber/inputNumberFormItemCol.vue";
import dateCtrl from "@/modules/components/date/dateCtrl.vue";
import dateFormItem from "@/modules/components/date/dateFormItem.vue";
import dateFormItemCol from "@/modules/components/date/dateFormItemCol.vue";
import timeCtrl from "@/modules/components/time/timeCtrl.vue";
import timeFormItem from "@/modules/components/time/timeFormItem.vue";
import timeFormItemCol from "@/modules/components/time/timeFormItemCol.vue";
import textareaCtrl from "@/modules/components/textarea/textareaCtrl.vue";
import textareaFormItem from "@/modules/components/textarea/textareaFormItem.vue";
import selectCtrl from "@/modules/components/select/selectCtrl.vue";
import selectFormItem from "@/modules/components/select/selectFormItem.vue";
import selectFormItemCol from "@/modules/components/select/selectFormItemCol.vue";
import radioCtrl from "@/modules/components/radio/radioCtrl.vue";
import radioFormItem from "@/modules/components/radio/radioFormItem.vue";
import checkboxCtrl from "@/modules/components/checkbox/checkboxCtrl.vue";
import checkboxFormItem from "@/modules/components/checkbox/checkboxFormItem.vue";
import checkboxFormItemCol from "@/modules/components/checkbox/checkboxFormItemCol.vue";
import seniorSchFormItem from "./seniorSearch/seniorSearchFormItem.vue";
import seniorSchFormItemCol from "./seniorSearch/seniorSearchFormItemCol.vue";
import textareaFormItemCol from "@/modules/components/textarea/textareaFormItemCol.vue";
import radioFormItemCol from "@/modules/components/radio/radioFormItemCol.vue";
const installComponent = app => {
  app.config.globalProperties.szdLoading = szdLoading;
  app.config.globalProperties.szdMsgBox = szdMsgBox;
  app.config.globalProperties.szdTLIfrDialog = szdTLIfrDialog;
  app.config.globalProperties.szdToken = getToken;
  app.config.globalProperties.szdTLCheck = szdCheck;
  app.component("szd-select", select);
  app.component("szd-radio", radio);
  app.component("szd-area", area);
  app.component("szd-pagination", pagination);
  app.component("szd-foot-status", footStatus);
  app.component("szd-wf-button", wfButton);
  app.component("szd-time", time);
  app.component("szd-date", date);
  app.component("szd-input-number", inputNumber);
  app.component("szd-checkbox", checkbox);
  app.component("szd-textarea", textarea);
  app.component("szd-tabs-wrap", tabsWrap);
  app.component("szd-sch", seniorSch);
  app.component("szd-input", input);
  app.component("szd-input", input);
  app.component("szd-input-ctrl", inputCtrl);
  app.component("szd-input-form-item", inputFormItem);
  app.component("szd-input-form-item-col", inputFormItemCol);
  app.component("szd-input-number", inputNumber);
  app.component("szd-input-number-ctrl", inputNumberCtrl);
  app.component("szd-input-number-form-item", inputNumberFormItem);
  app.component("szd-input-number-form-item-col", inputNumberFormItemCol);
  app.component("szd-date", date);
  app.component("szd-date-ctrl", dateCtrl);
  app.component("szd-date-form-item", dateFormItem);
  app.component("szd-date-form-item-col", dateFormItemCol);
  app.component("szd-time", time);
  app.component("szd-time-ctrl", timeCtrl);
  app.component("szd-time-form-item", timeFormItem);
  app.component("szd-time-form-item-col", timeFormItemCol);
  app.component("szd-textarea", textarea);
  app.component("szd-textarea-ctrl", textareaCtrl);
  app.component("szd-textarea-form-item", textareaFormItem);
  app.component("szd-textarea-form-item-col", textareaFormItemCol);
  app.component("szd-select", select);
  app.component("szd-select-ctrl", selectCtrl);
  app.component("szd-select-form-item", selectFormItem);
  app.component("szd-select-form-item-col", selectFormItemCol);
  app.component("szd-radio", radio);
  app.component("szd-radio-ctrl", radioCtrl);
  app.component("szd-radio-form-item", radioFormItem);
  app.component("szd-radio-form-item-col", radioFormItemCol);
  app.component("szd-checkbox", checkbox);
  app.component("szd-checkbox-ctrl", checkboxCtrl);
  app.component("szd-checkbox-form-item", checkboxFormItem);
  app.component("szd-checkbox-form-item-col", checkboxFormItemCol);
  app.component("szd-tabs-wrap", tabsWrap);
  app.component("szd-sch", seniorSch);
  app.component("szd-sch-form-item", seniorSchFormItem);
  app.component("szd-sch-form-item-col", seniorSchFormItemCol);
};
export default installComponent;
