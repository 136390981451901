<template>
  <el-time-picker
    v-model="_modelValue"
    value-format="HH:mm:ss"
    range-separator="-"
    :placeholder="placeholder"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :is-range="isRange"
    :disabled="disabled"
    :readonly="readonly"
    :clearable="clearable"
    @change="handleChange"
    @focus="handleFocus"
    @blur="handleBlur"
    :szdfst="szdfst" />
</template>
<script setup>
  import { defineProps, defineEmits, computed } from "vue";

  const props = defineProps({
    modelValue: {
      type: [String, Number, Date, Array]
    },
    placeholder: {
      type: String
    },
    startPlaceholder: {
      type: String,
      default: "开始时间"
    },
    endPlaceholder: {
      type: String,
      default: "结束时间"
    },
    isRange: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    szdfst: {
      type: String
    }
  });
  //更新数据对象返回值
  const emit = defineEmits(["update:modelValue", "change", "focus", "blur"]);
  //值数据
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
  //在 Input 获得焦点时触发
  const handleFocus = e => {
    emit("focus", e);
  };
  //在 Input 失去焦点时触发
  const handleBlur = e => {
    emit("blur", e);
  };
</script>
