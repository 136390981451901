// 跳转新页签
import store from "@/store";
import frmUserStore from "../../store/frmUser";
import { ElMessage } from "element-plus";

import userPer from "@/modules/sys/userPer/userPer.vue";

const baseURL = process.env.VUE_APP_BASE_API;
const sysCode = process.env.VUE_APP_SYS_CODE;

function _szdTLNav(_routerCode, params = {}, isMapRouterCode = false, isFixBlank = false) {
  // 检查路由码
  if (!_routerCode) {
    return ElMessage.error("路由代码不存在");
  }

  let routerCode = _routerCode.toUpperCase(); // 路由码
  let routerUrl = ""; // 路由地址
  let isBlank = false; // 是否新页签

  // 获取路由信息
  // const routesObj = store.state.coreStore.frmUser.routesObj;
  const routesObj = frmUserStore.state.routesObj;
  const route = routesObj[routerCode];
  console.log("routesObj", routesObj);
  // 检查路由信息
  if (!route || !route.path) {
    return ElMessage.error("路由地址不存在");
  }

  routerUrl = route.path;
  isBlank = isFixBlank || route.isFrame != 0;
  let isVnode = this && this.$vnode;

  // 判断是否是动态路由, 并构建动态路由

  if (routerUrl.includes("/:") && Object.keys(params).length > 0) {
    // 动态路由, params参数替换掉动态路由的参数
    const paramNames = routerUrl.split("/").filter(item => item.startsWith(":"));
    // 动态路由, params参数替换掉动态路由的参数
    paramNames.forEach(item => {
      const key = item.replace(":", "");
      routerUrl = routerUrl.replace(`:${key}`, params[key]);
      delete params[key];
    });
  }

  // 是否附带路由, 打印路由:routerCode-->printRouterCode
  if (isMapRouterCode) {
    return szdTLNav(route.printName, params, false);
  }

  const query = params;
  const urlQueryStr = tansParams(query);
  const urlQuery = urlQueryStr ? "?" + urlQueryStr : "";
  let urlPath = `${routerUrl}${urlQuery}`;

  // ===================跳转逻辑========================

  // 子应用打开非本子应用
  // 打开的页面非本子应用页面, 通过乾坤则取默认host直接打开
  // 打开的页面非本子应用页面, 不通过乾坤, 则加上sysWebIp

  if (sysCode != "CORE" && route.sysCode != "CORE" && sysCode != route.sysCode) {
    if (window.__POWERED_BY_QIANKUN__) {
      // 非新页签则框架自带micro, 新页签用open需要加上做路由匹配
      if (isBlank) {
        console.log("子调非本子,乾坤中,新页签", "/micro", `${urlPath}`);
        console.log("子调非本子,乾坤中,新页签:", `地址应为/micro${route.sysCode}开头`);
        return window.open(`/micro${urlPath}`, "_blank");
      } else {
        console.log("子调非本子,乾坤中,非新页签", "/micro", `${urlPath}`);
        console.log("子调非本子,乾坤中,非新页签:", `地址应为/micro${route.sysCode}开头`);
        history.pushState(null, "", `/micro${urlPath}`);
        // this.$router是本子应用的, 没有对应组件, 无法跳转
        // return this.$router.push({
        // 	path: `${routerUrl}`,
        // 	query: query
        // })
      }
    } else {
      const sysMicro = store.state.frmUser.sysMicro;
      const sysWebIp = sysMicro[route.sysCode].sysWebIp;
      urlPath = `${sysWebIp}${urlPath}`;
      // 如果是新页签
      if (isBlank) {
        console.log("子调非本子,非乾坤中,新页签", `${urlPath}`);
        console.log("子调非本子,非乾坤中,新页签:", `地址应为${route.sysCode}开头`);
        return window.open(`${urlPath}`, "_blank");
      } else {
        // 如果不是新页签
        console.log("子调非本子,非乾坤中,非新页签", `${urlPath}`);
        console.log("子调非本子,非乾坤中,非新页签:", `地址应为${route.sysCode}开头`);
        return (location.href = `${urlPath}`);
      }
    }
  }

  // 子应用打开本子应用
  // 新页签, 通过乾坤
  // 新页签, 不通过乾坤
  // 非新页签, 通过乾坤
  // 非新页签, 不通过乾坤

  if (route.sysCode == sysCode && sysCode != "CORE") {
    if (window.__POWERED_BY_QIANKUN__) {
      urlPath = !urlPath.startsWith("/micro") ? `/micro${urlPath}` : urlPath;
      if (isBlank) {
        console.log("子调子本,乾坤中,新页签", "/micro", `${urlPath}`);
        console.log("子调子本,乾坤中,新页签:", `地址应为/micro${sysCode}开头`);
        return window.open(`${urlPath}`, "_blank");
      } else {
        console.log("子调子本,乾坤中,非新页签", urlPath);
        console.log("子调子本,乾坤中,非新页签:", `地址应为/${sysCode}开头`);

        return isVnode
          ? this.$router.push({
              path: routerUrl,
              query: query
            })
          : history.pushState(null, "", `${urlPath}`);
      }
    } else {
      if (isBlank) {
        console.log("子调子本,非乾坤中,新页签", `${urlPath}`);
        console.log("子调子本,非乾坤中,新页签:", `地址应为/${sysCode}开头`);
        return window.open(`${urlPath}`, "_blank");
      } else {
        console.log("子调子本,非乾坤中,非新页签", urlPath);
        console.log("子调子本,非乾坤中,非新页签:", `地址应为/${sysCode}开头`);
        return isVnode
          ? this.$router.push({
              path: routerUrl,
              query: query
            })
          : history.pushState(null, "", `${urlPath}`);
      }
    }
  }

  // 子应用调用主应用页面
  // 乾坤中, 子打开主
  // 非乾坤中, 子打开主
  if (sysCode != "CORE" && route.sysCode == "CORE") {
    if (window.__POWERED_BY_QIANKUN__) {
      if (isBlank) {
        console.log("子调主,乾坤中,新页签", urlPath);
        console.log("子调主,乾坤中,新页签:", `地址应为/${"core"}开头`);
        return window.open(`${urlPath}`, "_blank");
      } else {
        console.log("子调主,乾坤中,非新页签", urlPath);
        console.log("子调主,乾坤中,非新页签:", `地址应为/${"core"}开头`);
        history.pushState(null, "", urlPath);
      }
    } else {
      if (isBlank) {
        const sysMicro = store.state.frmUser.sysMicro;
        const sysWebIp = sysMicro[route.sysCode].sysWebIp;
        urlPath = `${sysWebIp}${urlPath}`;
        console.log("子调主,非乾坤中,新页签", urlPath);
        console.log("子调主,非乾坤中,新页签:", `地址应为${sysWebIp}/${"core"}开头`);
        return window.open(`${urlPath}`, "_blank");
      } else {
        const sysMicro = store.state.frmUser.sysMicro;
        const sysWebIp = sysMicro[route.sysCode].sysWebIp;
        urlPath = `${sysWebIp}${urlPath}`;
        console.log("子调主,非乾坤中,非新页签", urlPath);
        console.log("子调主,非乾坤中,非新页签:", `地址应为${sysWebIp}/${"core"}开头`);
        location.href = `${urlPath}`;
      }
    }
  }

  // 主调用子
  // 乾坤中
  if (sysCode == "CORE" && route.sysCode != "CORE") {
    urlPath = !urlPath.startsWith("/micro") ? `/micro${urlPath}` : urlPath;
    if (isBlank) {
      console.log("主调子,新页签", urlPath);
      console.log("主调子,新页签:", `地址应为/micro/${sysCode}开头`);
      return window.open(`${urlPath}`, "_blank");
    } else {
      console.log("主调子,非新页签", urlPath);
      console.log("主调子,非新页签:", `地址应为/micro/${route.sysCode}开头`, isVnode);
      // return history.pushState(null, "", `${urlPath}`);
      return isVnode ? this.$router.push({ path: routerUrl, query: query }) : (location.href = urlPath);
    }
  }

  // 主调用主
  if (sysCode == "CORE" && route.sysCode == "CORE") {
    if (isBlank) {
      console.log("主调主,新页签:", urlPath);
      console.log("主调主,新页签:", "地址应为/core开头");

      return window.open(`${urlPath}`, "_blank");
    } else {
      console.log("主调主,非新页签", urlPath);
      console.log("主调主,非新页签:", "地址应为/core开头");
      return isVnode
        ? this.$router.push({
            path: routerUrl,
            query: query
          })
        : history.pushState(null, "", `${urlPath}`);
    }
  }
}
function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof value[key] !== "undefined") {
            let params = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}
// Vue.prototype.szdTLNav = _szdTLNav
function _szdTLGetUrl(_routerCode, params = {}) {
  if (!_routerCode) {
    return ElMessage.error("路由代码不存在");
  }

  let routerCode = _routerCode.toUpperCase();
  let routerUrl = "";
  // 判断isFrame
  const routesObj = frmUserStore.state.routesObj;
  const route = routesObj[routerCode];
  if (!route || !route.path) {
    return ElMessage.error(translateLang("路由地址不存在:") + _routerCode.toUpperCase());
  }

  routerUrl = route.path;

  if (routerUrl.includes("/:") && Object.keys(params).length > 0) {
    // 动态路由, params参数替换掉动态路由的参数
    const paramNames = routerUrl.split("/").filter(item => item.startsWith(":"));
    // 动态路由, params参数替换掉动态路由的参数
    paramNames.forEach(item => {
      const key = item.replace(":", "");
      routerUrl = routerUrl.replace(`:${key}`, params[key]);
      delete params[key];
    });
  }

  const sysMicro = frmUserStore.state.sysMicro;

  // 主应用打开iframe, 嵌套主应用页面
  // 主应用打开iframe, 嵌套子应用页面
  const query = params;
  const urlQueryStr = tansParams(query);

  const ifrTitle = route.iframeTitle || route.meta.title;
  const ifrWidth = route.iframeWidth || "";
  const ifrHeight = route.iframeHeight || "";

  let urlQuery = urlQueryStr ? `?${urlQueryStr}routerTitle=${ifrTitle}` : `?routerTitle=${ifrTitle}`;
  if (ifrWidth) urlQuery = `${urlQuery}&winWidth=${ifrWidth}`;
  if (ifrHeight) urlQuery = `${urlQuery}&winHeight=${ifrHeight}`;

  let urlPath = `${routerUrl}${urlQuery}`;

  let resUrl = "";

  // 主应用嵌套主应用页面
  if (sysCode == "CORE" && route.sysCode == "CORE") {
    resUrl = `${urlPath}`;
  }

  // 主应用嵌套子应用页面
  if (sysCode == "CORE" && route.sysCode != "CORE") {
    urlPath = !urlPath.startsWith("/micro") ? `/micro${urlPath}` : urlPath;
    resUrl = `${urlPath}`;
  }

  // 子应用嵌套主应用页面
  if (sysCode != "CORE" && route.sysCode == "CORE") {
    const sysWebIp = sysMicro[route.sysCode].sysWebIp;
    if (window.__POWERED_BY_QIANKUN__) {
      // 乾坤中
      resUrl = `${urlPath}`;
    } else {
      resUrl = `${sysWebIp}${urlPath}`;
    }
  }

  // 子应用嵌套非本子应用页面
  if (sysCode != "CORE" && route.sysCode != "CORE" && sysCode != route.sysCode) {
    const sysWebIp = sysMicro[route.sysCode].sysWebIp;
    if (window.__POWERED_BY_QIANKUN__) {
      // 乾坤中
      resUrl = `/micro${urlPath}`;
    } else {
      resUrl = `${sysWebIp}${urlPath}`;
    }
  }

  // 子应用嵌套本子应用页面
  if (sysCode == route.sysCode && sysCode != "CORE") {
    if (window.__POWERED_BY_QIANKUN__) {
      // 乾坤中
      resUrl = `/micro${urlPath}`;
    } else {
      resUrl = `${urlPath}`;
    }
  }

  return resUrl;
}

export function setGlobalFontsize() {
  const obj = JSON.parse(sessionStorage.getItem("SZDCORE-Admin-CoreLogin") ? sessionStorage.getItem("SZDCORE-Admin-CoreLogin") : "{}");
  console.log("obj", obj);
  let size = "12px";
  if (obj.userPer && obj.userPer.fontSize) {
    console.log("obj.userPer", obj.userPer);
    size = obj.userPer.fontSize;
  }
  document.getElementsByTagName("body")[0].style.setProperty("--fz", size);
  document.documentElement.style.setProperty("--el-font-size-base", size);
  document.documentElement.style.setProperty("--font-size", size);
}

export function translateLang(langKey) {
  let language = localStorage.getItem("szdLang") ? localStorage.getItem("szdLang") : "zh-CN";
  const dic = JSON.parse(localStorage.getItem("langDic") ? localStorage.getItem("langDic") : "{}");
  if (language === "zh-CN") {
    return langKey;
  }
  console.log("342", dic, langKey);
  return dic[langKey] ? dic[langKey] : langKey;
}
export const szdTLGetUrl = _szdTLGetUrl;
export const szdTLNav = _szdTLNav;
