<template>
	<!-- 选择用户 -->
	<el-dialog :title="dialogParams.title" :visible.sync="dialogParams.open" append-to-body :close-on-click-modal="false"
	  v-szd-dialog="[500,200]" class="app-dialog-container">
		<el-form class="app-el-form">
			<el-row>
				<el-col :span="24">
					<el-form-item label="用户">
						<el-col :span="8">
							<el-input v-model="form.tranUserId">
								<i class="el-icon-search" slot="suffix" @click="szdSh('CORE_USER',3,form,['tranUserId-userId','tranUserName-userName'])" />
							</el-input>
						</el-col>
						<el-col :span="16">
							<szd-desc v-model="form.tranUserName" />
						</el-col>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer">
			<el-button type="primary" @click="okayForm">确 定</el-button>
			<el-button type="primary" @click="colseForm">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogParams: {
					title: "用户选择",
					open: false
				},
				form: {
					operate: "", //操作事件
					tranUserId: "", //转办和前加签
					tranUserName: "", //转办和前加姓名
					addUserId: "", //前加签ID
					addUserName: "", //前加签姓名
				}
			}
		},
		methods: {
			//接受并处理
			acceptData(operateFlag) {
				this.initData();
				this.dialogParams.open = true
				this.form.operate = operateFlag
			},
			//确定
			okayForm() {
				if (this.checkOkayForm()) {
					if (this.form.operate == 'but_transfer') {
						this.form.addUserId = '';
						this.form.addUserName = '';
					} else {
						this.form.addUserId = this.form.tranUserId;
						this.form.addUserName = this.form.tranUserName;
						this.form.tranUserId = '';
						this.form.tranUserName = '';
					}
					this.$emit('okaySubmit', this.form)
					this.dialogParams.open = false;
				}
			},
			// 检查提交数据
			checkOkayForm() {
				if (this.form.tranUserId == '' || this.form.tranUserId.trim() == '') {
					this.$message.error("用户不能为空");
					return false;
				}
				return true;
			},
			//关闭
			colseForm() {
				this.dialogParams.open = false;
			},
			//初始化数据
			initData() {
				this.form.operate = ""
				this.form.tranUserId = ""
				this.form.tranUserName = ""
				this.form.addUserId = ""
				this.form.addUserName = ""
			}
		}
	}
</script>
