<template>
  <!-- 城市搜索帮助    -->
  <div style="width: 100%; height: 100%">
    <el-cascader
      v-model="sval"
      :options="options"
      key="city"
      placeholder=""
      :props="{ expandTrigger: 'hover', emitPath: false }"
      :clearable="clearable == 'false' ? false : true"
      :show-all-levels="false"
      :disabled="disabled"
      @change="changeData"
      popper-class="szd-el-cascader" />
  </div>
</template>

<script>
  import store from "@/store"; //全局引入vuex
  import { getCityList } from "../api/searchHelpCity";
  export default {
    name: "SearchHelpCityView",
    model: {
      event: "change"
    },
    store,
    //  disabled 是否可编辑 clearable 清空按钮
    props: ["disabled", "clearable", "modelValue"],
    data() {
      return {
        options: [],
        sval: this.value //当前下来框value值
      };
    },
    created() {
      this.getCityListInfo();
    },
    watch: {
      modelValue(val) {
        this.sval = val; //②监听外部对props属性result的变更，并同步到组件内的data属性
      }
    },
    methods: {
      getCityListInfo() {
        this.options = [];
        if (this.checkMemory("1", "SZDCITY")) {
          getCityList().then(res => {
            if (res.code === 200) {
              this.options = res.data;
              if (!this.nomemory) {
                let jsonObj = {
                  key: "SZDCITY",
                  values: this.options
                };
                this.$store.commit("components/SET_memoryBaseField", jsonObj);
              }
            }
          });
        }
      },
      // Vuex存在值，使用Vuex数据
      checkMemory(meoryType, key) {
        if (this.nomemory) {
          return true;
        }
        let meroyList = [];
        if (meoryType == "1") {
          meroyList = this.$store.state.components.memoryBaseField;
        }
        for (let i = 0; i < meroyList.length; i++) {
          if (meroyList[i].key == key) {
            this.options = meroyList[i].values;
            return false;
          }
        }
        return true;
      },
      //返回选中值
      changeData() {
        this.$emit("update:modelValue", this.sval);
      }
    }
  };
</script>

<style>
  .szd-el-cascader {
    border-radius: 2px;
  }

  .szd-el-cascader .el-cascader-panel {
    font-size: $fz;
  }

  .szd-el-cascader .el-cascader-node {
    height: 30px;
    line-height: 30px;
  }
</style>
