// import Vue from 'vue'
import dayjs from "dayjs";
/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 YYYY-MM-DD YYYY-MM-DD HH:mm:ss:SS
 */

function fmtDate(time, fmt) {
  // dt 为秒值
  let d = "";
  if (time) d = new Date(time);
  else d = new Date();
  const o = {
    "M+": d.getMonth() + 1, // 月份
    "D+": d.getDate(), // 日
    "H+": d.getHours(), // 小时
    "m+": d.getMinutes(), // 分
    "s+": d.getSeconds(), // 秒
    "q+": Math.floor((d.getMonth() + 3) / 3), // 季度
    "S+": d.getMilliseconds(), // 毫秒
  };
  // 根据Y个数替换年
  if (/(Y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (d.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  // 替换其他, 十位无值替换为0
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

function szdTLFmtDtA(time, fmt) {
  if (time) {
    return dayjs(time).format(fmt);
  } else {
    return dayjs(new Date()).format(fmt);
  }
}

// 当前日期格式化 YYYY-MM-DD
function szdTLFmtDtB() {
  // return fmtDate("", "YYYY-MM-DD");
  return dayjs(new Date()).format("YYYY-MM-DD");
}

// 返回星期几
function szdTLWkDay(time) {
  let d = "";
  if (time) d = new Date(time);
  else d = new Date();
  const wkDay = d.getDay();
  let weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  return weekday[wkDay];
}

// 获取两个日期的相差日期数(differ 1-相差天数 2-相差小时数 3-相差分钟数 4-相差秒数)
function szdTLDiffDt(sDate, eDate, differ) {
  //将两个日期字符串转化为日期对象
  var startDate = new Date(sDate);
  var endDate = new Date(eDate);
  //计算两个日期相差的毫秒数
  var msecNum = endDate.getTime() - startDate.getTime();
  //获取转化比
  var format;
  if (differ == 1) {
    format = parseFloat(24 * 60 * 60 * 1000); //天数跟毫秒数的比例
  } else if (differ == 2) {
    format = parseFloat(60 * 60 * 1000); //小时数跟毫秒数的比例
  } else if (differ == 3) {
    format = parseFloat(60 * 1000); //分钟数跟毫秒数的比例
  } else if (differ == 4) {
    format = parseFloat(1000); //秒数跟毫秒数的比例
  }
  //计算两个日期相差的天数
  var dayNum = Math.floor(msecNum / format);
  return dayNum;
}

const installDateFn = (app) => {
  app.config.globalProperties.szdTLFmtDtA = szdTLFmtDtA;
  app.config.globalProperties.szdTLFmtDtB = szdTLFmtDtB;
  app.config.globalProperties.szdTLWkDay = szdTLWkDay;
  app.config.globalProperties.szdTLDiffDt = szdTLDiffDt;
};
export default installDateFn;
