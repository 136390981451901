<template>
  <div style="width: 100%; height: 100%" :szdfst="szdfst">
    <el-select v-model="sval" filterable :placeholder="placeholder" :clearable="clearable" @change="changeData" :disabled="disabled" :szdfst="szdfst">
      <el-option v-for="(item, index) in options" :key="index" :label="item.svalname" :value="item.svalue" />
    </el-select>
  </div>
</template>

<script>
  import { selectBaseView, selectDictView } from "../api/selectOption";

  export default {
    name: "selectOption",
    model: {
      event: "change"
    },
    //1.数据字典 dict 2.页面传值 darr 映射关系 setMapping 3.基础字段 baseField 查询条件 selectData
    // monitorObj 重新初始化下拉框数据 nullObj 赋值为空  disabled 是否可编辑 clearable 清空按钮 nomemory 不使用内存
    // noSval="true" 不拼装 valFirst="true" 必输选择第一个 szdfst 字段状态 placeholder占位符
    props: [
      "dict",
      "darr",
      "setMapping",
      "baseField",
      "selectData",
      "monitorObj",
      "nullObj",
      "modelValue",
      "disabled",
      "clearable",
      "nomemory",
      "noSval",
      "valFirst",
      "szdfst",
      "placeholder",
      "objData",
      "objParms"
    ],
    data() {
      return {
        options: [], //options: [{svalue:"",sname:""}] 下拉框循环值
        fieldMapping: this.setMapping,
        sval: this.modelValue //当前下来框value值
      };
    },
    created() {
      //数据字典
      if (this.dict !== undefined && this.dict !== "") {
        this.reqDic();
      }
      //页面传值
      if (Array.isArray(this.darr) && this.darr.length) {
        this.reqArr(this.darr);
      }
      //基础字段
      if (this.baseField !== undefined && this.baseField !== "") {
        this.reqBaseField();
      }
    },
    watch: {
      modelValue(val) {
        this.sval = val; //②监听外部对props属性result的变更，并同步到组件内的data属性myResult中

        // 如果传递的值是空的, 需要重新做组件初始化
        if (!val) {
          //数据字典
          if (this.dict !== undefined && this.dict !== "") {
            this.reqDic();
          }
          //页面传值
          if (Array.isArray(this.darr) && this.darr.length) {
            this.reqArr(this.darr);
          }
          //基础字段
          if (this.baseField !== undefined && this.baseField !== "") {
            this.reqBaseField();
          }
        }
      },
      sval() {
        // 值变化时出发input事件, 更新外部v-model的值
        this.$emit("update:modelValue", this.sval);
      },
      monitorObj(val) {
        //页面传值
        if (Array.isArray(this.darr) && this.darr.length) {
          this.reqArr(this.darr);
        }
        //基础字段
        if (this.baseField !== undefined && this.baseField !== "") {
          this.reqBaseField();
        }
      },
      nullObj(val) {
        this.sval = undefined;
        //页面传值
        if (Array.isArray(this.darr) && this.darr.length) {
          this.reqArr(this.darr);
        }
        //基础字段
        if (this.baseField !== undefined && this.baseField !== "") {
          this.reqBaseField();
        }
      },
      selectData(val) {
        //基础字段
        if (this.baseField !== undefined && this.baseField !== "") {
          this.reqBaseField();
        }
      }
    },
    methods: {
      //字典查询
      reqDic() {
        this.options = [];
        if (this.checkMemory("0", this.dict)) {
          selectDictView(this.dict).then(res => {
            if (res.code === 200) {
              this.options = res.data;
              this.repProssData();
              if (!this.nomemory) {
                let jsonObj = {
                  key: this.dict,
                  values: this.options
                };
                this.$store.commit("components/SET_memoryDict", jsonObj);
              }
              this.valFirstProssData();
            }
          });
        }
      },
      //页面传值
      reqArr(val) {
        this.options = [];
        if (this.setMapping !== undefined && this.setMapping !== "") {
          let arr = this.setMapping.split(",");
          for (let i = 0; i < val.length; i++) {
            let opJson = {
              svalue: val[i][arr[0]],
              sname: val[i][arr[1]]
            };
            this.options.push(opJson);
          }
        } else {
          for (let i = 0; i < val.length; i++) {
            let opJson = {
              svalue: val[i]["svalue"],
              sname: val[i]["sname"]
            };
            this.options.push(opJson);
          }
        }
        this.repProssData();
        this.valFirstProssData();
      },
      //基础字段
      reqBaseField() {
        this.options = [];
        let meorryKey = this.baseField;
        let arr = [];
        if (Array.isArray(this.selectData) && this.selectData.length) {
          for (let i = 0; i < this.selectData.length; i++) {
            let sParm = {};
            sParm["paramName"] = this.selectData[i][0];
            sParm["paramValue"] = this.selectData[i][1];
            sParm["queryMode"] = "=";
            arr.push(sParm);
            meorryKey = meorryKey + this.selectData[i][1];
          }
        }
        if (this.checkMemory("1", meorryKey)) {
          let requestJson = {};
          requestJson["_paramGroup"] = arr;
          requestJson["baseField"] = this.baseField;
          selectBaseView(requestJson).then(res => {
            if (res.code === 200) {
              this.options = res.data;
              this.repProssData();
              if (!this.nomemory) {
                let jsonObj = {
                  key: meorryKey,
                  flag: true,
                  values: this.options
                };
                this.$store.commit("components/SET_memoryBaseField", jsonObj);
              }
              this.valFirstProssData();
            }
          });
        }
      },
      // Vuex存在值，使用Vuex数据
      checkMemory(meoryType, key) {
        if (this.nomemory) {
          return true;
        }
        let meroyList = [];
        if (meoryType === "0") {
          meroyList = this.$store.state.components.memoryDict;
        } else if (meoryType === "1") {
          meroyList = this.$store.state.components.memoryBaseField;
        }
        for (let i = 0; i < meroyList.length; i++) {
          if (meroyList[i].key === key) {
            this.options = meroyList[i].values;
            this.valFirstProssData();
            return false;
          }
        }
        return true;
      },
      //处理结果数据
      repProssData() {
        if (Array.isArray(this.options) && this.options.length) {
          for (let i = 0; i < this.options.length; i++) {
            if (this.noSval === "true" || this.noSval === true) {
              this.options[i].svalname = this.options[i].sname;
            } else {
              this.options[i].svalname = this.options[i].svalue + "." + this.options[i].sname;
            }
          }
        }
      },
      //必输默认首个值
      valFirstProssData() {
        if (this.valFirst === "true" || this.valFirst === true) {
          if (!this.sval) {
            if (Array.isArray(this.options) && this.options.length) {
              this.sval = this.options[0].svalue;
              this.changeData();
            }
          }
        }
      },
      //返回选中值
      changeData() {
        let l_resOption = {};
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].svalue === this.sval) {
            l_resOption = this.options[i];
            break;
          }
        }
        if (this.objData !== undefined && Array.isArray(this.objParms) && this.objParms.length) {
          //赋值
          for (let j = 0; j < this.objParms.length; j++) {
            if (this.objParms[j].indexOf("=") > -1) {
              let fileNameArr = this.objParms[j].split("=");
              if (fileNameArr[1] === undefined || fileNameArr[1] === "undefined") {
                this.objData[fileNameArr[0]] = undefined;
              } else {
                this.objData[fileNameArr[0]] = fileNameArr[1];
              }
            } else {
              if (this.objParms[j].indexOf("-") === -1) {
                this.objData[this.objParms[j]] = l_resOption[this.objParms[j]];
              } else {
                let fileNameArr = this.objParms[j].split("-");
                this.objData[fileNameArr[0]] = l_resOption[fileNameArr[1]];
              }
            }
          }
        }
        this.$emit("change", this.sval, l_resOption);
      }
    }
  };
</script>
