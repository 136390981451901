<template>
  <el-input
    v-model="_modelValue"
    :placeholder="placeholder"
    :maxlength="maxlength"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="clearable"
    :input-style="{ textAlign: _align }"
    :oninput="handleOninput"
    @input="handleInput"
    @change="handleChange"
    @focus="handleFocus"
    @blur="handleBlur"
    :szdfst="szdfst">
    <template #suffix>
      <el-icon v-if="showHelp">
        <!-- emit('help') 直接调用父组件方法 -->
        <Search @click.prevent.stop="disabled ? '' : emit('help')" />
      </el-icon>
    </template>
  </el-input>
</template>

<script setup>
  import { ElMessage } from "element-plus";
  import { addThousandSeparator, removeThousandSeparator } from "pixiu-number-toolkit";
  import { defineProps, defineEmits, computed, onMounted } from "vue";

  const props = defineProps({
    modelValue: {
      type: [String, Number]
    },
    //类型支持string,number
    type: {
      type: String,
      default: "string"
    },
    numDot: {
      type: Boolean,
      default: false
    },
    numDec: {
      type: Number
    },
    numMax: {
      type: Number
    },
    numMin: {
      type: Number
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: Number
    },
    showHelp: {
      type: [Boolean, Number, String],
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    align: {
      type: String
    },
    szdfst: {
      type: String
    }
  });
  //定义事件
  const emit = defineEmits(["update:modelValue", "change", "input", "focus", "blur", "help"]);
  //值数据
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //对齐方式 文本默认左对齐，数值默认右对齐
  const _align = computed(() => {
    let alignTemp = "left";
    if (props.align) {
      alignTemp = props.align;
    } else if (props.type === "number") {
      alignTemp = "right";
    }
    return alignTemp;
  });
  //钩子函数
  onMounted(() => {
    //小数位设置
    if (props.type === "number" && !isNaN(props.numDec) && props.numDec >= 0) {
      _modelValue.value = _modelValue.value ? Number(_modelValue.value).toFixed(props.numDec) : _modelValue.value;
    }
  });
  //原生输入事件
  const handleOninput = e => {
    if (props.type === "number") {
      e.target.value = e.target.value
        .replace(/[^\-\d.]/g, "") // 只能输入.和-和数字
        .replace(/^\./g, "") //第一个字符不能是.
        .replace(/\.{2,}/g, ".") // 不能连续输入.
        .replace(/(\.\d+)\./g, "$1") // .后面不能再输入.
        .replace(/(-)\./g, "$1") // -后面不能输入.
        .replace(/\-{2,}/g, "-") // -只能保留一个
        .replace(/(\d+|\.)-/g, "$1") // 数字和.后面不能接-,不能出现类似11-, 12.-
        .replace(/-(0){2,}/g, "$1") // 不能出现-00,-001,-0001类似
        .replace(/(-)0+(\d+)/g, "$1$2") // 不能出现-01,-02类似
        .replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      if (!isNaN(props.numDec) && props.numDec >= 0) {
        e.target.value = e.target.value.replace(new RegExp("(\\.\\d{" + props.numDec + "})\\d*"), "$1"); // 最多保留2位小数
      }
    }
  };
  //文本输入事件
  const handleInput = v => {
    emit("input", v);
  };
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
  //当选择器的输入框获得焦点时触发,
  const handleFocus = e => {
    //去除千分位符
    if (props.type === "number") {
      e.target.value = removeThousandSeparator(e.target.value);
    }
    emit("focus", e);
  };
  //当选择器的输入框失去焦点时触发
  const handleBlur = e => {
    //数值类型进行检查
    if (props.type === "number") {
      //检查数值大小
      if (!isNaN(props.numMax) && Number(e.target.value) > props.numMax) {
        ElMessage.error(`最大值为:${props.numMax}`);
        e.target.value = "";
        return;
      }
      if (!isNaN(props.numMin) && Number(e.target.value) < props.numMin) {
        ElMessage.error(`最小值为:${props.numMin}`);
        e.target.value = "";
        return;
      }
      //添加小数位，与千分位符顺序不能错
      if (!isNaN(props.numDec) && props.numDec >= 0) {
        e.target.value = Number(e.target.value).toFixed(props.numDec);
      }
      //设置千分位付
      if (props.numDot) {
        e.target.value = addThousandSeparator(e.target.value);
      }
    }
    emit("blur", e);
  };
</script>
