<template>
	<el-dialog :title="dialogParams.title" :visible.sync="dialogParams.open" append-to-body
		:close-on-click-modal="false"  v-szd-dialog="[1000,500]" class="app-dialog-container">
		<div style="width: 100%;height:100%;">
			<iframe :src="src" id="web_mon_clog_record" frameborder="0" style="width: 100%;height: 100%;" scrolling="auto" />
		</div>
	</el-dialog>
</template>

<script>
	import store from '@/store/index' //全局引入vuex
	export default {
		store,
		data() {
			return {
				dialogParams: {
					title: "修改记录",
					open: false,
				},
				src: '',
			}
		},
		methods: {
			//接受参数
			acceptData(bussId) {
				this.src = this.szdTLGetUrl('CORE_MON_25A', {
					bussId: bussId
				})
				this.dialogParams.open = true;
			},
		}
	}
</script>
