import request from '@/modules/common/utils/request'

//新增初始化
export function wfInit(routerCode) {
	return request({
		url: '/core/wf/service/init/' + routerCode,
		method: 'get',
	})
}

//工作流处理事件 wfParamEvt wfParamBuss
export function wfProcess(params) {
	return request({
		url: '/core/wf/service/process',
		method: 'post',
		data: params
	})
}

//批处理工作流处理事件
export function wfBatchProcess(params) {
	return request({
		url: '/core/wf/service/batchProcess',
		method: 'post',
		data: params
	})
}

//审批信息
export function wfApprInfo(bussId) {
	return request({
		url: '/core/wf/service/apprInfo/' + bussId,
		method: 'get'
	})
}

//消费记录
export function wfEisConsume(bussId) {
	return request({
		url: '/core/wf/service/eis/consume/' + bussId,
		method: 'get'
	})
}

//消费记录-票夹
export function wfEisConsumeFol(bussId, data) {
	return request({
		url: '/core/wf/service/eis/consume/fol/' + bussId,
		method: 'post',
		data: data
	})
}

//消费记录字段转换
export function wfEisConsumeConvert(data) {
	return request({
		url: '/core/wf/service/eis/consume/convert',
		method: 'post',
		data: data
	})
}

//电子影像
export function wfEisImg(params) {
	return request({
		url: '/core/wf/service/eis/img',
		method: 'post',
		data: params
	})
}
//附件
export function wfEisAtt(params) {
	return request({
		url: '/core/wf/service/eis/att',
		method: 'post',
		data: params
	})
}

//评审
export function wfAddMeeting(bussId) {
	return request({
		url: '/core/wf/per/meet/send/add/' + bussId,
		method: 'post',
	})
}

//评审消息详情
export function wfMsgList(bussId, nodeDoc) {
	return request({
		url: '/core/wf/per/meet/msg/list/' + bussId + '/' + nodeDoc,
		method: 'get'
	})
}

// 接口调用流程审批
export function wfApproveRemote(wfEvt) {
	return request({
		url: '/core/wf/service/approveRemote',
		method: 'post',
		data: wfEvt
	})
}

// 查看凭证
export function wfVch(bussId) {
	return request({
		url: `/core/vch/buss/${bussId}`,
		method: 'get'
	})
}