<template>
  <div class="right_page" v-if="dataList.length" ref="rightPage" id="onlineApiRightPage" :style="{ left: leftNum + 'px' }">
    <div class="r_p_title">本页目录</div>
    <div class="r_p_list">
      <div class="r_p_item" v-for="(item, index) in dataList" :key="item.id" @click="handleClick(item)" :class="{ r_p_item_active: id == item.id }">
        {{ index + 1 + ". " + item.title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WorkspaceJsonRightPage",
    props: {
      dataList: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        // dataList: [
        //     { title: '方法类', id: 'wwww' },
        //     { title: '标签类', id: 'wwww2' },
        // ],
        id: null,
        leftNum: 0
      };
    },
    mounted() {
      this.getLeft()();
      window.addEventListener("resize", this.getLeft());
      this.$nextTick(()=>{
        window.addEventListener("scroll", this.setActiveSection);
      })

    },
    methods: {
      getLeft() {
        return () => {
          this.$nextTick(() => {
            let dom = this.$refs.rightPage;
            if (dom) {
              this.leftNum = dom.parentNode.getBoundingClientRect().left + dom.parentNode.offsetWidth + 20;
            }
          });
        };
      },
      handleClick(item) {
        this.id = item.id;
        var dom = document.getElementById(item.id);
        if (dom) {
          window.scrollTo(0, dom.offsetTop);
        }
      },
      setActiveSection() {
        const sections = this.$parent.rightTitleArr;
        const directoryItems = document.querySelectorAll(".r_p_item");
        const scrollPosition = window.scrollY;
        sections.forEach((item, index) => {
          const section = document.getElementById(item.id);
          if(section){
          const sectionTop = section.offsetTop - 50;
          if (scrollPosition >= sectionTop) {
            directoryItems.forEach(item => item.classList.remove("r_p_item_active"));
            directoryItems[index].classList.add("r_p_item_active");
          }
          }
        });
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.getLeft());
    }
  };
</script>

<style lang="scss" scoped>
  @media (max-width: 1100px) {
    .right_page {
      display: none !important;
    }
  }

  .right_page {
    // position: absolute;
    // right: 100px;
    position: fixed;
    top: 103px;
    z-index: 100;
    height: calc(100% - 103px);

    .r_p_title {
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 2;
      color: #000;
    }

    .r_p_list {
      display: flex;
      flex-direction: column;
      // border-left: 2px solid #e6e6e6;
      height: calc(100% - 33px);
      overflow: auto;

      .r_p_item {
        position: relative;
        cursor: pointer;
        padding-left: 12px;
        font-size: 14px;
        padding-top: 4px;
        padding-bottom: 4px;
        line-height: 1.5;
        color: #666;
        word-wrap: break-word;
        border-left: 2px solid #e6e6e6;
      }

      .r_p_item_active {
        color: #00a4ff;

        &::before {
          content: "";
          position: absolute;
          left: -2px;
          top: 6px;
          bottom: 6px;
          border-left: 2px solid #00a4ff;
        }
      }
    }
  }
</style>
