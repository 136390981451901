<template>
  <el-date-picker
    v-model="_modelValue"
    :type="type"
    :value-format="formatVal[type]"
    :format="formatVal[type]"
    range-separator="-"
    :placeholder="placeholder"
    :start-placeholder="$t(startPlaceholder)"
    :end-placeholder="$t(endPlaceholder)"
    :disabled="disabled"
    :readonly="readonly"
    :clearable="clearable"
    @change="handleChange"
    @focus="handleFocus"
    @blur="handleBlur"
    :szdfst="szdfst" />
</template>
<script setup>
  import { defineProps, defineEmits, computed } from "vue";

  const props = defineProps({
    type: {
      type: String,
      default: "date"
    },
    modelValue: {
      type: [String, Date, Array]
    },
    placeholder: {
      type: String
    },
    startPlaceholder: {
      type: String,
      default: "开始时间"
    },
    endPlaceholder: {
      type: String,
      default: "结束时间"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    szdfst: {
      type: String
    }
  });
  const formatVal = {
    year: "YYYY",
    years: "YYYY",
    month: "YYYY-MM",
    date: "YYYY-MM-DD",
    dates: "YYYY-MM-DD",
    datetime: "YYYY-MM-DD HH:mm:ss",
    week: "YYYY第ww周",
    datetimerange: "YYYY-MM-DD HH:mm:ss",
    daterange: "YYYY-MM-DD",
    monthrange: "YYYY-MM"
  };

  //更新数据对象返回值
  const emit = defineEmits(["update:modelValue", "change", "focus", "blur"]);
  //值数据
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
  //在 Input 获得焦点时触发
  const handleFocus = e => {
    emit("focus", e);
  };
  //在 Input 失去焦点时触发
  const handleBlur = e => {
    emit("blur", e);
  };
</script>
