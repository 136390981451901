<template>
  <template v-if="_ctrl.descSpan > 0 && _ctrl.descSpan < 24">
    <el-row style="width: 100%">
      <el-col :span="24 - _ctrl.descSpan">
        <szd-checkbox v-model="_modelValue" :label="_ctrl.boxLabel" :disabled="_ctrl.disabled" :szdfst="_ctrl.szdfst" @change="handleChange" />
      </el-col>
    </el-row>
  </template>
  <template v-else>
    <szd-checkbox v-model="_modelValue" :label="_ctrl.boxLabel" :disabled="_ctrl.disabled" :szdfst="_ctrl.szdfst" @change="handleChange" />
  </template>
</template>

<script setup>
  import { defineProps, defineEmits, computed } from "vue";

  const props = defineProps({
    //输入框所需的参数
    modelValue: {
      type: [String, Number, Boolean, Array]
    },
    //控件参数
    ctrl: {
      type: Object
    }
  });
  const emit = defineEmits(["update:modelValue", "change"]);
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //控件参数
  const _ctrl = computed(() => {
    let ctrl = {
      boxLabel: props.ctrl.boxLabel, //标签
      disabled: props.ctrl.disabled, //不可用
      descSpan: props.ctrl.descSpan, //描述宽度
      szdfst: props.ctrl.szdfst //字段状态
    };
    return ctrl;
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
</script>
