<template>
	<!-- 同意和后加签页面 -->
	<el-dialog :title="dialogParams.title" :visible.sync="dialogParams.open" append-to-body
		:close-on-click-modal="false"  v-szd-dialog="[600,350]" class="app-dialog-container">
		<el-form class="app-el-form">
			<el-row>
				<el-col :span="24">
					<div class="main-textarea">
						<div style="width:25%;" class="main-textarea-lable">审批意见</div>
						<div style="width:75%;" class="main-textarea-content">
							<el-input type="textarea" v-model="form.comments" maxlength="150" rows="5" show-word-limit
								class="main-textarea-input" />
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="常用语">
						<szd-select v-model="form.dailyItem" dict="CORE_WF_DALY" :nullObj="form.dailyItem"
							@change="changeComments" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-if="form.operate=='but_postAddSign'">
				<el-col :span="24">
					<el-form-item label="加签">
						<el-col :span="8">
							<el-input v-model="form.addUserId">
								<i class="el-icon-search" slot="suffix"
									@click="szdSh('CORE_USER',3,form,['addUserId-userId','addUserName-userName'])" />
							</el-input>
						</el-col>
						<el-col :span="16">
							<szd-desc v-model="form.addUserName" />
						</el-col>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-form-item label="@用户ID">
					<el-input v-model="form.noticeUserIds">
						<i class="el-icon-search" slot="suffix"
							@click="szdSh('CORE_USER',6,form,['noticeUserIds-userId','noticeUserNames-userName'])" />
					</el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item label="@用户姓名">
					<el-input v-model="form.noticeUserNames" disabled></el-input>
				</el-form-item>
			</el-row>
		</el-form>
		<div slot="footer">
			<el-button type="primary" @click="okayForm">确 定</el-button>
			<el-button type="primary" @click="colseForm">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogParams: {
					title: "审批意见",
					open: false,
				},
				form: {
					operate: "", //操作事件
					comments: "", //审批意见
					noticeUserIds: "", //@用户ID
					noticeUserNames: "", //@用户姓名
					addUserId: "", //加签ID
					addUserName: "", //加签姓名
					dailyItem: "", //日常用语
				},
			}
		},
		methods: {
			//接受并处理
			acceptData(operateFlag) {
				this.initData();
				this.dialogParams.open = true
				this.form.operate = operateFlag
			},
			//确定
			okayForm() {
				if (this.checkOkayForm()) {
					this.$emit('okaySubmit', this.form)
					this.dialogParams.open = false;
				}
			},
			// 检查提交数据
			checkOkayForm() {
				if (this.form.comments == '' || this.form.comments.trim() == '') {
					this.$message.error("审批意见不能为空");
					return false;
				}
				if (this.form.operate == 'but_postAddSign') {
					if (this.form.addUserId == '' || this.form.addUserId.trim() == '') {
						this.$message.error("加签用户不能为空");
						return false;
					}
				}
				return true;
			},
			//关闭
			colseForm() {
				this.dialogParams.open = false;
			},
			//常用语赋值审批意见
			changeComments(sval, resOption) {
				this.form.comments = this.form.comments + resOption.sname
			},
			//初始化数据
			initData() {
				this.form.operate = ""
				this.form.comments = ""
				this.form.noticeUserIds = ""
				this.form.noticeUserNames = ""
				this.form.addUserId = ""
				this.form.addUserName = ""
				this.form.dailyItem = ""
			}
		}
	}
</script>
