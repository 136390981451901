<template>
  <!-- 尾部状态栏显示 -->
  <div class="main-wf-foot-status">
    <div v-for="(item, index) in footList" :key="index" class="foot-status">
      <div v-if="item.lable && item.lable != ''" class="foot-lable">{{ item.lable }}:</div>
      <div v-if="item.status == 'E'" class="foot-value-red">{{ item.value }}</div>
      <div v-else-if="item.status == 'W'" class="foot-value-yellor">{{ item.value }}</div>
      <div v-else class="foot-value-blue">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["modelValue"],
    data() {
      return {
        footList: this.modelValue
        // footStatus: [{
        // 	lable: '超标警告',
        // 	status: 'W',
        // 	value: '30%',
        // }, {
        // 	lable: '超标禁止',
        // 	status: 'E',
        // 	value: '10%',
        // }, {
        // 	lable: '报账金额',
        // 	status: 'S',
        // 	value: '100',
        // }]
      };
    },
    watch: {
      modelValue(val) {
        this.footList = val;
      }
    }
  };
</script>
