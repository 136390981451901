<script setup>
  import { ElDialog } from "element-plus";
  import { ref } from "vue";
  import { onMounted } from "vue";
  import dialog from "../../directives/dialog/dialog.js";
  const showCustomMessage = ref(true);

  const props = defineProps({
    title: {
      type: String,
      default: ""
    },
    src: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "95%"
    },
    height: {
      type: String,
      default: "85%"
    }
  });
  const vSzdDialog = dialog;
  onMounted(() => {
    console.log("width", props.width, props.height);
  });
</script>

<template>
  <el-dialog :title="props.title" v-model="showCustomMessage" :width="props.width" align-center append-to-body destroy-on-close>
    <div style="width: 100%; height: 100%; position: relative" v-szd-dialog="[width, height]">
      <iframe :src="props.src" frameborder="0" style="width: 100%; height: 100%" scrolling="auto" />
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
  :deep(.el-dialog) {
    width: auto;
    height: auto;

    .el-dialog__header {
      padding: 0;
      margin-right: 0;
    }

    .el-dialog__body {
      padding: 0.8rem 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        color: #000;
        font-size: 0.24rem;
        font-weight: 500;
      }
    }
  }
</style>
