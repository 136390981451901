<template>
  <!--限制为input-->
  <div class="g-seniorWrap">
    <template v-if="type === 'string' || type === 'number'">
      <szd-input
        style="width: 100%"
        v-model="ranges[0].low"
        :type="type"
        :num-dot="numDot"
        :num-dec="numDec"
        :show-help="_showHelp"
        @help="dealSingleHelp('low')"
        :readonly="readonly"
        :disabled="onlyNull.includes(ranges[0].option)"
        @change="changeSingle" />
      <span v-if="!noTo" class="u-sp"> - </span>
      <szd-input
        v-if="!noTo"
        style="width: 100%"
        v-model="ranges[0].high"
        :type="type"
        :num-dot="numDot"
        :num-dec="numDec"
        :show-help="_showHelp"
        @help="dealSingleHelp('high')"
        :readonly="readonly"
        :disabled="onlyNull.includes(ranges[0].option)"
        @change="changeSingle" />
    </template>
    <!--限制为时间-->
    <template v-else-if="type === 'time'">
      <el-time-picker
        style="width: 100%"
        :value-format="dateFormat"
        v-model="ranges[0].low"
        :readonly="readonly"
        :disabled="onlyNull.includes(ranges[0].option)"
        @change="changeSingle"
        clearable />
      <span v-if="!noTo" class="u-sp"> - </span>
      <el-time-picker
        v-if="!noTo"
        style="width: 100%"
        :value-format="dateFormat"
        v-model="ranges[0].high"
        :readonly="readonly"
        :disabled="onlyNull.includes(ranges[0].option)"
        @change="changeSingle"
        clearable />
    </template>
    <!--限制为日期或日期时间-->
    <template v-else>
      <el-date-picker
        style="width: 100%"
        :type="type"
        :value-format="dateFormat"
        :format="dateFormat"
        v-model="ranges[0].low"
        :readonly="readonly"
        :disabled="onlyNull.includes(ranges[0].option)"
        @change="changeSingle"
        clearable />
      <span v-if="!noTo" class="u-sp"> - </span>
      <el-date-picker
        style="width: 100%"
        :type="type"
        :value-format="dateFormat"
        :format="dateFormat"
        v-model="ranges[0].high"
        :readonly="readonly"
        :disabled="onlyNull.includes(ranges[0].option)"
        @change="changeSingle"
        clearable />
    </template>
    <!--高级搜索按钮 固定占位 -->
    <div class="u-btnWrap">
      <el-button v-if="!noExt" class="u-open" :class="['u-ruleBtn', modelValue.length > 0 ? 'active' : '']" @click="showDialog" icon="Switch">
      </el-button>
    </div>
  </div>
  <!--高级查询条件弹框-->
  <el-dialog
    draggable
    align-center
    v-model="visible"
    append-to-body
    title="高级查询"
    :before-close="cancel"
    destroy-on-close
    class="app-dialog-container">
    <div v-szd-dialog="[800, 500]" />
    <div class="main-table-title">
      <div class="main-table-title-left" style="width: 100%">
        <span>注意：高级查询条件行之间是或的关系，维护查询条件时请注意是否存在影响。</span>
      </div>
    </div>
    <div class="szd__tb__wrapper">
      <el-table :data="ranges" border>
        <el-table-column prop="option" label="操作符" style="width: 20%">
          <template v-slot="scope">
            <el-select v-model="scope.row.option" @change="changeOperator($event, scope.row)" :disabled="readonly" clearable>
              <el-option
                v-for="item in filterOperators"
                :disabled="scope.row.high === '' && onlyHigh.includes(item.value)"
                :key="item.value"
                :value="item.value"
                :label="item.label" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="low" :label="!noTo ? '低值' : '值'" style="width: 30%">
          <template v-slot="scope">
            <szd-input
              v-if="type === 'string' || type === 'number'"
              v-model="scope.row.low"
              :type="type"
              :num-dot="numDot"
              :num-dec="numDec"
              :show-help="_showHelp"
              @help="dealMultiHelp('low', scope.$index)"
              :readonly="readonly"
              :disabled="onlyNull.includes(scope.row.option)"
              @change="changeLow($event, scope.row)"
              @click="focusRow(scope.$index)" />
            <el-time-picker
              v-else-if="type === 'time'"
              :value-format="dateFormat"
              v-model="scope.row.low"
              :readonly="readonly"
              :disabled="onlyNull.includes(scope.row.option)"
              @change="changeLow($event, scope.row)"
              @focus="focusRow(scope.$index)"
              clearable />
            <el-date-picker
              v-else
              style="width: 100%"
              :type="type"
              :value-format="dateFormat"
              :format="dateFormat"
              v-model="scope.row.low"
              :readonly="readonly"
              :disabled="onlyNull.includes(scope.row.option)"
              @change="changeLow($event, scope.row)"
              @focus="focusRow(scope.$index)"
              clearable />
          </template>
        </el-table-column>
        <el-table-column v-if="!noTo" prop="high" label="高值" style="width: 30%">
          <template v-slot="scope">
            <szd-input
              v-if="type === 'string' || type === 'number'"
              v-model="scope.row.high"
              :type="type"
              :num-dot="numDot"
              :num-dec="numDec"
              :show-help="_showHelp"
              @help="dealMultiHelp('high', scope.$index)"
              :readonly="readonly"
              :disabled="onlyNull.includes(scope.row.option)"
              @change="changeHigh($event, scope.row)"
              @click="focusRow(scope.$index)" />
            <el-time-picker
              v-else-if="type === 'time'"
              :value-format="dateFormat"
              v-model="scope.row.high"
              :readonly="readonly"
              :disabled="onlyNull.includes(scope.row.option)"
              @change="changeHigh($event, scope.row)"
              @focus="focusRow(scope.$index)"
              clearable />
            <el-date-picker
              v-else
              style="width: 100%"
              :type="type"
              :value-format="dateFormat"
              :format="dateFormat"
              v-model="scope.row.high"
              :readonly="readonly"
              :disabled="onlyNull.includes(scope.row.option)"
              @change="changeHigh($event, scope.row)"
              @focus="focusRow(scope.$index)"
              clearable />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template v-slot="scope">
            <el-button type="primary" link @click="remove(scope.$index)" :disabled="readonly" icon="Delete"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <el-button type="primary" @click="addRanges" :disabled="readonly">新增 </el-button>
      <el-button type="primary" @click="paste" :disabled="readonly">粘贴 </el-button>
      <el-button type="primary" @click="clearRanges" :disabled="readonly">清空 </el-button>
      <el-button type="primary" @click="confirm" :disabled="readonly">确认 </el-button>
      <el-button type="primary" @click="cancel">取消</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
  import { ref, watch, computed, defineProps, defineEmits, getCurrentInstance } from "vue";
  import { ElMessage } from "element-plus";
  import { szdSh, szdShShow } from "../mainSerchHelp";
  import _ from "lodash";

  const props = defineProps({
    //类型支持string,number,date,datetime,time,year,month
    type: {
      type: String,
      default: "string" //默认字符串
    },
    format: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    numDot: {
      type: Boolean,
      default: false
    },
    numDec: {
      type: Number
    },
    //[基础字段-单值,要回填的字段-单值,查询条件(数组)]
    help: {
      type: Array,
      default: () => []
    },
    noTo: {
      type: Boolean,
      default: false
    },
    noExt: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  });
  //单值操作符
  const onlyLow = ["EQ", "NE", "GE", "LE", "GT", "LT", "SP", "NS", "CP", "NC", "EN", "NN"];
  //范围操作符
  const onlyHigh = ["BT", "NB"];
  //日期和数值不允许使用的操作符
  const onlyLike = ["SP", "NS", "CP", "NC"];
  //空操作符禁用输入框框
  const onlyNull = ["EN", "NN"];
  //操作符描述
  const operators = [
    { value: "EQ", label: "等于" },
    { value: "NE", label: "不等于" },
    { value: "GE", label: "大于或等于" },
    { value: "LE", label: "小于或等于" },
    { value: "GT", label: "大于" },
    { value: "LT", label: "小于" },
    { value: "SP", label: "包含" },
    { value: "NS", label: "不包含" },
    { value: "CP", label: "*通配符包含" },
    { value: "NC", label: "*通配符不包含" },
    { value: "BT", label: "范围内" },
    { value: "NB", label: "范围外" },
    { value: "EN", label: "等于空" },
    { value: "NN", label: "不等于空" }
  ];
  //日期格式化
  const dateFormat = computed(() => {
    let dateFormat = "";
    switch (props.type) {
      case "date":
        dateFormat = props.format ? props.format : "YYYY-MM-DD";
        break;
      case "datetime":
        dateFormat = props.format ? props.format : "YYYY-MM-DD HH:mm:ss";
        break;
      case "year":
        dateFormat = "YYYY";
        break;
      case "month":
        dateFormat = "YYYY-MM";
        break;
      case "time":
        dateFormat = "HH:mm:ss";
        break;
    }
    return dateFormat;
  });
  //是否显示搜索帮助
  const _showHelp = computed(() => {
    return props.help.length > 0;
  });
  //查询数据
  const ranges = ref([
    {
      type: props.type,
      format: dateFormat.value,
      option: "",
      low: "",
      high: ""
    }
  ]);
  //初始化行数据
  const addRanges = () => {
    ranges.value.push({
      type: props.type,
      format: dateFormat.value,
      option: "",
      low: "",
      high: ""
    });
  };
  //显示Dialog
  const visible = ref(false);
  //克隆原始值
  const initRanges = ref([]);
  //光标当前行
  const cursorRowIndex = ref(0);
  //更新数据对象返回值
  const emit = defineEmits(["update:modelValue"]);

  //单输入框值改变索帮助
  const dealSingleHelp = key => {
    szdSh(props.help[0], 1, ranges.value[0], [key + "-" + props.help[1]], 0, props.help[2]).then(res => {
      changeSingle();
    });
  };
  //单输入框值改变处理
  const changeSingle = () => {
    if (onlyNull.includes(ranges.value[0].option)) {
      ranges.value[0].low = ranges.value[0].high = "";
    } else if (!props.noTo) {
      if ((ranges.value[0].high === "" || ranges.value[0].high == null) && ranges.value[0].low) {
        ranges.value[0].option = onlyLow.includes(ranges.value[0].option) ? ranges.value[0].option : "EQ";
      } else if (ranges.value[0].high) {
        const low = props.type === "number" ? Number(ranges.value[0].low) : ranges.value[0].low;
        const high = props.type === "number" ? Number(ranges.value[0].high) : ranges.value[0].high;
        if (low > high) {
          ElMessage.error(`低值 ${ranges.value[0].low} 不能大于高值 ${ranges.value[0].high} `);
          ranges.value[0].high = "";
          return;
        }
        ranges.value[0].option = onlyHigh.includes(ranges.value[0].option) ? ranges.value[0].option : "BT";
      } else if ((ranges.value[0].high === "" || ranges.value[0].high == null) && (ranges.value[0].low === "" || ranges.value[0].low == null)) {
        ranges.value[0].option = "";
      }
    } else {
      if (ranges.value[0].low) {
        ranges.value[0].option = "EQ";
      } else {
        ranges.value[0].option = "";
      }
    }
    const result = delDuplicate();
    emit("update:modelValue", result);
  };

  //弹出Dialog对话框
  const showDialog = () => {
    //初始化光标行
    cursorRowIndex.value = 0;
    //克隆原始值
    initRanges.value = _.cloneDeep(ranges.value);
    //显示弹窗
    visible.value = true;
  };
  //下拉框显示设置
  const filterOperators = computed(() => {
    if (props.type === "string") {
      return operators;
    } else {
      //不显示匹配数据
      return operators.filter(item => !onlyLike.includes(item.value));
    }
  });
  //操作符改变时值处理，当运算符清空时高低值随之清空
  const changeOperator = (e, row) => {
    if (e === "" || e == null || onlyNull.includes(row.option)) {
      row.low = row.high = "";
    } else if (!onlyHigh.includes(e)) {
      row.high = "";
    }
  };
  //低值改变时触发业务
  const changeLow = (e, row) => {
    if (e && row.option === "" && row.high === "") {
      row.option = "EQ";
    }
  };
  //高值改变时触发业务
  const changeHigh = (e, row) => {
    if (e && (onlyLow.includes(row.option) || row.option === "")) {
      row.option = "BT";
    } else if ((e === "" || e == null) && onlyHigh.includes(row.option)) {
      row.option = "EQ";
    }
  };
  //点击按钮处理，获取光标当前所在行
  const focusRow = rowIndex => {
    cursorRowIndex.value = rowIndex;
  };
  //删除行数据
  const remove = index => {
    ranges.value.splice(index, 1);
    if (ranges.value.length === 0) {
      addRanges();
    }
  };
  //清空数据
  const clearRanges = () => {
    ranges.value = [];
    for (let i = 0; i < 10; i++) {
      addRanges();
    }
  };
  //取消事件，还原初始值
  const cancel = () => {
    ranges.value = _.cloneDeep(initRanges.value);
    visible.value = !visible.value;
  };
  //确认数据
  const confirm = () => {
    //检查有效性
    if (checkValidate.value > -1) {
      return ElMessage.error(`低值 ${ranges.value[checkValidate.value].low} 不能大于高值 ${ranges.value[checkValidate.value].high} `);
    }
    //删除无效行和重复行
    const result = delDuplicate();
    emit("update:modelValue", result);
    cancel();
  };
  //检查数据维护是否正确
  const checkValidate = computed(() => {
    let passedIndex = -1;
    passedIndex = ranges.value.findIndex(item => {
      const low = props.type === "number" ? Number(item.low) : item.low;
      const high = props.type === "number" ? Number(item.high) : item.high;
      return onlyHigh.includes(item.option) && low > high;
    });
    return passedIndex;
  });
  //删除无效行和重复行
  const delDuplicate = () => {
    const res = ranges.value.filter(item => item.option !== "");
    return Array.from(new Set(res.map(JSON.stringify)), JSON.parse);
  };
  //多选数据进行处理
  const dealMultiHelp = (key, index) => {
    szdShShow(props.help[0], props.help[2], key === "low" ? 2 : 1).then(res => {
      let idx = index;
      res.forEach(item => {
        ranges.value[idx][key] = item[props.help[1]];
        if (key === "low") {
          ranges.value[idx]["option"] = ranges.value[idx]["option"] ? ranges.value[idx]["option"] : "EQ";
        } else {
          ranges.value[idx]["option"] = onlyHigh.includes(ranges.value[idx]["option"]) ? ranges.value[idx]["option"] : "BT";
        }
        idx++;
        if (idx >= ranges.value.length) {
          addRanges();
        }
      });
    });
  };
  //监听数值变化
  watch(
    () => props.modelValue,
    val => {
      ranges.value = _.cloneDeep(val);
      if (ranges.value.length < 10) {
        for (let i = 0, len = 10 - ranges.value.length; i < len; i++) {
          addRanges();
        }
      }
      //记录初始值
      initRanges.value = _.cloneDeep(ranges.value);
    },
    //监听器会在初始化时立即执行
    { immediate: true }
  );
  /*粘贴事件*/
  const paste = async () => {
    try {
      const res = await navigator.clipboard.readText();
      const rows = res
        .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, function (match, p1) {
          return p1.replace(/""/g, '"').replace(/\r\n|\n\r|\n|\r/g, " ");
        })
        .split(/\r\n|\n\r|\n|\r/g);
      //检查最后一行是否为空
      if (rows.length > 0 && rows[rows.length - 1] === "") {
        rows.splice(rows.length - 1, 1);
      }
      if (rows.length === 0) return;
      //检查数据是否合规
      if (props.type !== "string") {
        let regex = "";
        let flagCheck = false;
        if (props.type === "number") {
          regex = /^\d+(\.\d+)?$/;
        } else if (props.type === "date") {
          regex = /^\d{4}-\d{2}-\d{2}$/;
        } else if (props.type === "datetime") {
          regex = /^\d{4}-\d{2}-\d{2} ([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
        } else if (props.type === "time") {
          regex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
        } else if (props.type === "year") {
          regex = /^\d{4}$/;
        } else if (props.type === "month") {
          regex = /^\d{4}-\d{2}$/;
        }
        for (let i = 0; i < rows.length; i++) {
          const arrTemp = rows[i].split("\t");
          if (arrTemp.length > 0 && arrTemp[0] && !regex.test(arrTemp[0])) {
            ElMessage.error(`值 ${arrTemp[0]} 格式错误 `);
            flagCheck = true;
            break;
          }
          if (arrTemp.length > 1 && arrTemp[1] && !regex.test(arrTemp[1])) {
            ElMessage.error(`值 ${arrTemp[0]} 格式错误 `);
            flagCheck = true;
            break;
          }
        }
        if (flagCheck) {
          return;
        }
      }
      let idx = cursorRowIndex.value;
      rows.forEach(item => {
        const arrVal = item.split("\t");
        //检查是否存在值
        if (arrVal.length > 0) {
          if (props.noTo) {
            ranges.value[idx].option = "EQ";
            ranges.value[idx].low = arrVal[0];
            ranges.value[idx].high = "";
          } else {
            if (arrVal.length > 1 && arrVal[1]) {
              ranges.value[idx].option = "BT";
              ranges.value[idx].low = arrVal[0];
              ranges.value[idx].high = arrVal[1];
            } else {
              ranges.value[idx].option = "EQ";
              ranges.value[idx].low = arrVal[0];
              ranges.value[idx].high = "";
            }
          }
        } else {
          ranges.value[idx].option = "";
          ranges.value[idx].low = "";
          ranges.value[idx].high = "";
        }
        idx++;
        if (idx >= ranges.value.length) {
          addRanges();
        }
      });
    } catch (err) {
      console.error("粘贴数据失败: ", err);
    }
  };
</script>
<style scoped lang="scss">
  .g-seniorWrap {
    display: flex;
    width: 100%;
    align-items: center;

    :deep(.el-input) {
      flex: 1;
    }

    .u-sp {
      padding: 0 5px;
    }

    .u-open {
      width: 26px;
      height: 26px;
      margin: 0;
    }
  }

  .m-btn {
    text-align: right;
    padding-bottom: 5px;
  }

  .u-ruleBtn {
    margin-left: 5px;
    font-weight: 600;

    &.active {
      color: #008f01;
      background-color: #b3e19d;
      border-color: #b3e19d;
    }
  }

  :deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
    width: 100%;
  }

  .el-table :deep(tr.hide) {
    display: none;
  }

  .el-select-dropdown__item {
    font-size: $fz;
  }

  .u-btnWrap {
    height: 26px;
    width: 35px;
    line-height: 26px;
    text-align: right;
    display: flex;
    justify-content: end;
  }
</style>
