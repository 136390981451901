<template>
  <template v-if="_ctrl.descSpan > 0 && _ctrl.descSpan < 24">
    <el-row style="width: 100%">
      <el-col :span="24 - _ctrl.descSpan">
        <szd-radio
          v-model="_modelValue"
          :dict="_ctrl.dict"
          :darr="_ctrl.darr"
          :setMapping="_ctrl.setMapping"
          :baseField="_ctrl.baseField"
          :selectData="_ctrl.selectData"
          :disabled="_ctrl.disabled"
          :nomemory="_ctrl.nomemory"
          :szdfst="_ctrl.szdfst"
          @change="handleChange" />
      </el-col>
    </el-row>
  </template>
  <template v-else>
    <szd-radio
      v-model="_modelValue"
      :dict="_ctrl.dict"
      :darr="_ctrl.darr"
      :setMapping="_ctrl.setMapping"
      :baseField="_ctrl.baseField"
      :selectData="_ctrl.selectData"
      :disabled="_ctrl.disabled"
      :nomemory="_ctrl.nomemory"
      :szdfst="_ctrl.szdfst"
      @change="handleChange" />
  </template>
</template>

<script setup>
  import { defineProps, defineEmits, computed } from "vue";

  const props = defineProps({
    //输入框所需的参数
    modelValue: {
      type: [String, Number, Boolean]
    },
    //控件参数
    ctrl: {
      type: Object
    }
  });
  const emit = defineEmits(["update:modelValue", "change"]);
  const _modelValue = computed({
    get: () => props.modelValue,
    set: val => {
      emit("update:modelValue", val);
    }
  });
  //控件参数
  const _ctrl = computed(() => {
    let ctrl = {
      dict: props.ctrl.dict, //数据字典
      darr: props.ctrl.darr, //数组
      setMapping: props.ctrl.setMapping, //映射关系
      baseField: props.ctrl.baseField, //基础字段
      selectData: props.ctrl.selectData, //查询条件
      disabled: props.ctrl.disabled, //不可用
      nomemory: props.ctrl.nomemory, //不使用内存
      descSpan: props.ctrl.descSpan, //描述宽度
      szdfst: props.ctrl.szdfst //字段状态
    };
    return ctrl;
  });
  //更改事件
  const handleChange = v => {
    emit("change", v);
  };
</script>
