export default {
  mounted(el, binding) {
    const per = binding.value[0];
    const divHeight = binding.value[1];
    const minHeight = binding.value[2];
    let h = document.body.clientHeight * per - divHeight;
    if (minHeight != undefined) {
      if (h < minHeight) h = minHeight;
    } else {
      if (h < 100) h = 100;
    }
    el.style.height = h + "px";
  },
};
