<template>
  <div style="width: 100%; height: 100%" :szdfst="szdfst">
    <el-radio-group v-model="sval" class="radio-group-box" @change="handleChange" :disabled="disabled" :szdfst="szdfst">
      <el-radio v-for="(item, index) in options" :key="index" :label="item.svalue">
        {{ item.sname }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
  import { selectBaseView, selectDictView } from "../api/selectOption";

  export default {
    name: "selectOptionView",
    model: {
      event: "change"
    },
    //1.数据字典 dict 2.页面传值 darr 映射关系 setMapping 3.基础字段 baseField 查询条件 selectData
    // disabled 是否可编辑 不使用内存 nomemory szdfst 字段状态
    props: ["dict", "darr", "setMapping", "baseField", "selectData", "modelValue", "disabled", "nomemory", "szdfst"],
    data() {
      return {
        options: [], //options: [{svalue:"",sname:""}] 下拉框循环值
        fieldMapping: this.setMapping,
        sval: this.modelValue //当前下来框value值
      };
    },
    mounted() {
      //数据字典
      if (this.dict !== undefined && this.dict !== "") {
        this.reqDic();
      }
      //页面传值
      if (Array.isArray(this.darr) && this.darr.length) {
        this.reqArr(this.darr);
      }
      //基础字段
      if (this.baseField !== undefined && this.baseField !== "") {
        this.reqBaseField();
      }
    },
    watch: {
      modelValue(val) {
        this.sval = val; //②监听外部对props属性result的变更，并同步到组件内的data属性myResult中
      }
    },
    methods: {
      //字典查询
      reqDic() {
        this.options = [];
        if (this.checkMemory("0", this.dict)) {
          this.$nextTick(() => {
            selectDictView(this.dict).then(res => {
              if (res.code === 200) {
                this.options = res.data;
                this.repProssData();
                if (!this.nomemory) {
                  let jsonObj = {
                    key: this.dict,
                    values: this.options
                  };
                  this.$store.commit("components/SET_memoryDict", jsonObj);
                }
              }
            });
          });
        }
      },
      //页面传值
      reqArr(val) {
        this.options = [];
        if (this.setMapping !== undefined && this.setMapping !== "") {
          let arr = this.setMapping.split(",");
          for (let i = 0; i < val.length; i++) {
            let opJson = {
              svalue: val[i][arr[0]],
              sname: val[i][arr[1]]
            };
            this.options.push(opJson);
          }
        } else {
          this.options = val;
        }
      },
      //基础字段
      reqBaseField() {
        this.options = [];
        let requestJson = {};
        let arr = [];
        if (Array.isArray(this.selectData) && this.selectData.length) {
          for (let i = 0; i < this.selectData.length; i++) {
            let sParm = {};
            sParm["paramName"] = this.selectData[i][0];
            sParm["paramValue"] = this.selectData[i][1];
            sParm["queryMode"] = "=";
            arr.push(sParm);
          }
        }
        requestJson["_paramGroup"] = arr;
        requestJson["baseField"] = this.baseField;
        selectBaseView(requestJson).then(res => {
          if (res.code === 200) {
            this.options = res.data;
          }
        });
      },
      // Vuex存在值，使用Vuex数据
      checkMemory(meoryType, key) {
        if (this.nomemory) {
          return true;
        }
        let meroyList = [];
        if (meoryType === "0") {
          meroyList = this.$store.state.components.memoryDict;
        } else if (meoryType === "1") {
          meroyList = this.$store.state.components.memoryBaseField;
        }
        for (let i = 0; i < meroyList.length; i++) {
          if (meroyList[i].key === key) {
            this.options = meroyList[i].values;
            return false;
          }
        }
        return true;
      },
      //处理结果数据
      repProssData() {
        if (Array.isArray(this.options) && this.options.length) {
          for (let i = 0; i < this.options.length; i++) {
            this.options[i].svalname = this.options[i].svalue + "." + this.options[i].sname;
          }
        }
      },
      //返回选中值
      handleChange() {
        this.$emit("update:modelValue", this.sval);
      }
    }
  };
</script>

<style scoped>
  .radio-group-box {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
</style>
