<template>
  <!-- 工作流按钮组件 -->
  <div class="but">
    <el-button
      type="primary"
      v-if="checkShow('A')"
      :disabled="checkAuthBut('but_save')"
      @click="clickButton('but_check')"
      >检查</el-button
    >
    <el-button
      type="primary"
      v-if="checkShow('B')"
      :disabled="checkAuthBut('but_save')"
      @click="clickButton('but_save')"
      >保存</el-button
    >
    <el-button
      type="primary"
      v-if="checkShow('C')"
      :disabled="checkAuthBut('but_commit')"
      @click="clickButton('but_commit')"
      >提交
    </el-button>
    <el-button
      type="primary"
      v-if="checkShow('D')"
      :disabled="checkAuthBut('but_approve')"
      @click="clickButton('but_approve')"
      >同意
    </el-button>
    <el-button
      type="primary"
      v-if="checkShow('E')"
      :disabled="checkAuthBut('but_reject')"
      @click="clickButton('but_reject')"
      >驳回
    </el-button>
    <el-button
      type="primary"
      v-if="checkShow('F')"
      :disabled="checkAuthBut('but_print')"
      @click="clickButton('but_print')"
      >打印</el-button
    >
    <el-button
      type="primary"
      v-if="checkShow('G')"
      :disabled="checkAuthBut('but_image')"
      @click="clickButton('but_image')"
      >影像</el-button
    >
    <el-button
      type="primary"
      v-if="checkShow('H')"
      :disabled="checkAuthBut('but_record')"
      @click="clickButton('but_record')"
      >审批信息
    </el-button>
    <el-button
      type="primary"
      v-if="checkShow('S')"
      @click="clickButton('but_vch')"
      >查看凭证
    </el-button>
    <el-button
      type="primary"
      class="but_more"
      v-if="checkShow('I')"
      @click="handleButMore"
      >更多 . . .</el-button
    >
    <div
      v-show="butMoreShow"
      class="but_more_view"
      @mouseleave="handleMouseOut"
    >
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('J')"
          :disabled="checkAuthBut('but_atta')"
          @click="clickButton('but_atta')"
          >附件</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('K')"
          :disabled="checkAuthBut('but_revoke')"
          @click="clickButton('but_revoke')"
          >撤回</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('L')"
          :disabled="checkAuthBut('but_transfer')"
          @click="clickButton('but_transfer')"
          >转办</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('M')"
          :disabled="checkAuthBut('but_meeting')"
          @click="clickButton('but_meeting')"
          >评审</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('N')"
          :disabled="checkAuthBut('but_preAddSign')"
          @click="clickButton('but_preAddSign')"
          >前加签</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('O')"
          :disabled="checkAuthBut('but_postAddSign')"
          @click="clickButton('but_postAddSign')"
          >后加签</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('P')"
          :disabled="checkAuthBut('but_delete')"
          @click="clickButton('but_delete')"
          >作废</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('Q')"
          :disabled="checkAuthBut('but_dyWfId')"
          @click="clickButton('but_dyWfId')"
          >设置流程</el-button
        >
      </div>
      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="checkShow('R')"
          :disabled="checkAuthBut('but_save')"
          @click="clickButton('but_consume')"
          >消费记录</el-button
        >
      </div>

      <div class="but_row">
        <el-button
          type="primary"
          class="but_more"
          v-if="bussObj"
          @click="clickButton('but_bussinfo')"
          >业务主体
        </el-button>
      </div>
    </div>
    <!-- 子页面 -->
    <wfButtonAppr ref="wfButtonAppr" @okaySubmit="okaySubmit" />
    <wfButtonTran ref="wfButtonTran" @okaySubmit="okaySubmit" />
    <wfBussInfoDg ref="wfBussInfoDg" />
    <wfDyDesigner ref="wfDyDesigner" />
  </div>
</template>

<script>
import { wfEisConsume, wfAddMeeting } from "../api/wfApi.js";
import "./wfButton.css";
import wfButtonAppr from "./wfButtonAppr.vue";
import wfButtonTran from "./wfButtonTran.vue";
import wfBussInfoDg from "../wfBussInfo/wfBussInfoDg.vue";
import wfDyDesigner from "../wfDyDesigner/wfDyDesigner.vue";
export default {
  props: ["wfRouterCode", "wfOper", "wfButShow", "bussObj"],
  components: {
    wfButtonAppr,
    wfButtonTran,
    wfBussInfoDg,
    wfDyDesigner,
  },
  data() {
    return {
      butMoreShow: false,
    };
  },
  created() {},
  methods: {
    //检查是否显示
    checkShow(flag) {
      if (this.wfButShow && this.wfButShow.indexOf(flag) != -1) {
        return false;
      }
      return true;
    },
    //检查权限
    checkAuthBut(butFname) {
      let temp = this.wfOper.wfBut[butFname];
      if (temp == "" || temp == undefined || temp == null) {
        return true;
      }
      return false;
    },
    //点击更多
    handleButMore() {
      this.butMoreShow = !this.butMoreShow;
    },
    //鼠标移出事件
    handleMouseOut() {
      this.butMoreShow = false;
    },
    //按钮操作
    clickButton(operateFlag) {
      switch (operateFlag) {
        case "but_approve": //同意
          this.$refs.wfButtonAppr.acceptData(operateFlag);
          break;
        case "but_reject": //驳回
          this.$refs.wfButtonAppr.acceptData(operateFlag);
          break;
        case "but_print": //打印
          this.printDoc();
          break;
        case "but_atta": //附件
          this.attaDoc();
          break;
        case "but_image": //影像
          this.imageDoc();
          break;
        case "but_record": //审批信息
          this.getWfInfo();
          break;
        case "but_revoke": //撤回
          this.revokeDoc(operateFlag);
          break;
        case "but_transfer": //转办
          this.$refs.wfButtonTran.acceptData(operateFlag);
          break;
        case "but_meeting": //评审
          this.meetingDoc();
          break;
        case "but_preAddSign": //前加签
          this.$refs.wfButtonTran.acceptData(operateFlag);
          break;
        case "but_postAddSign": //后加签
          this.$refs.wfButtonAppr.acceptData(operateFlag);
          break;
        case "but_delete": //作废
          this.deleteDoc(operateFlag);
          break;
        case "but_dyWfId": //设置动态工作流
          this.setDyWfId(operateFlag);
          break;
        case "but_consume": //消费记录
          this.consumeDoc();
          break;
        case "but_vch": //凭证预览
          this.showVch();
          break;
        case "but_bussinfo": //业务主体
          this.$refs.wfBussInfoDg.acceptData(this.bussObj);
          break;
        default:
          this.submitDoc(operateFlag);
          break;
      }
      // 回调方法
      this.$emit("wfExitAfter", operateFlag);
    },
    // 打印单据
    printDoc() {
      if (
        this.wfRouterCode != undefined &&
        this.wfOper.bussId != undefined &&
        this.wfOper.bussId != ""
      ) {
        this.szdTLWfReqRouterPrint(this.wfRouterCode, this.wfOper.bussId);
      }
    },
    // 撤回单据
    revokeDoc(operateFlag) {
      this.szdPop("是否撤回单据,请确认?").then(() => {
        this.submitDoc(operateFlag);
      });
    },
    // 评审单据
    meetingDoc() {
      if (this.wfOper.bussId != undefined && this.wfOper.bussId != "") {
        wfAddMeeting(this.wfOper.bussId).then((res) => {
          this.szdTLNav("CORE_PER_07A", {
            bussId: this.wfOper.bussId,
          });
        });
      }
    },
    // 作废单据
    deleteDoc(operateFlag) {
      if (this.wfOper.bussDocId != undefined && this.wfOper.bussDocId != "") {
        this.$refs.wfButtonAppr.acceptData(operateFlag, this.wfOper);
      } else {
        this.szdPop("删除的单据不能恢复,请确认?").then(() => {
          this.submitDoc(operateFlag);
        });
      }
    },
    //消费记录
    consumeDoc() {
      if (this.wfOper.bussId != undefined && this.wfOper.bussId != "") {
        wfEisConsume(this.wfOper.bussId).then((response) => {
          if (response.code == 200) {
            this.$emit("wfConsume", response.data);
          }
        });
      }
    },
    //提交数据
    submitDoc(operateFlag) {
      let wfevt = {
        operate: operateFlag, //操作事件
      };
      this.okaySubmit(wfevt);
    },
    //工作流事件提交
    okaySubmit(wfevt) {
      // this.lockBut();
      wfevt.userId = this.wfOper.userId;
      wfevt.bussId = this.wfOper.bussId;
      wfevt.bussDocId = this.wfOper.bussDocId;
      this.$emit("wfSubmit", wfevt);
    },
    //设置动态流程
    setDyWfId() {
      this.$refs.wfDyDesigner.acceptData(
        this.wfOper.wfBut.but_dyWfId,
        this.wfOper.wfBut.userId
      );
    },
    //调用附件系统
    attaDoc() {
      let paramEis = {
        bussId: this.wfOper.bussId,
        userId: this.wfOper.userId,
        authFlag: this.wfOper.wfBut.but_image,
      };
      this.szdEisatt(paramEis);
    },
    //调用影像系统
    imageDoc() {
      let paramEis = {
        bussId: this.wfOper.bussId,
        userId: this.wfOper.userId,
        authFlag: this.wfOper.wfBut.but_image,
      };
      this.szdEisimg(paramEis);
    },
    //审批信息
    getWfInfo() {
      this.szdTLIfrDialog("CORE_WF_11A", { bussId: this.wfOper.bussId });
    },
    showVch() {
      this.szdTLIfrDialog("CORE_VCH_01B", {
        bussId: this.wfOper.bussId,
        width: "75%",
        height: "65%",
      });
    },
    //锁定按钮
    // lockBut() {
    // 	this.wfOper.wfBut.but_check = '';
    // 	this.wfOper.wfBut.but_save = '';
    // 	this.wfOper.wfBut.but_commit = '';
    // 	this.wfOper.wfBut.but_approve = '';
    // 	this.wfOper.wfBut.but_reject = '';
    // 	this.wfOper.wfBut.but_print = '';
    // 	this.wfOper.wfBut.but_atta = '';
    // 	this.wfOper.wfBut.but_image = '';
    // 	this.wfOper.wfBut.but_record = '';
    // 	this.wfOper.wfBut.but_transfer = '';
    // 	this.wfOper.wfBut.but_meeting = '';
    // 	this.wfOper.wfBut.but_preAddSign = '';
    // 	this.wfOper.wfBut.but_postAddSign = '';
    // 	this.wfOper.wfBut.but_delete = '';
    // 	this.wfOper.wfBut.but_dyWfId = '';
    // 	this.wfOper.wfBut.but_consume = '';
    // },
  },
};
</script>
<!-- 参数说明
 // wfOper: {
 //    bussId: "",
 //    bussDocId: "",
 //    userId: "", //处理人
 //    routerCode:"",//业务路由
 //    wfBut: {}, //工作流按钮
 //    wfFst: {}, //屏幕字段状态
 //    wfDef: {} //缺省值
 // },
 // wfBut: {
 // 	but_check: "", //检查
 // 	but_save: "", //保存
 // 	but_commit: "", //提交
 // 	but_approve: "", //同意
 // 	but_reject: "", //驳回
 // 	but_print: "", //打印
 // 	but_atta: "", //附件
 // 	but_image: "", //影像
 // 	but_record: "", //审批信息
 // 	but_revoke: "", //撤回
 // 	but_transfer: "", //转办
 // 	but_meeting: "", //评审
 // 	but_preAddSign: "", //前加签
 // 	but_postAddSign: "", //后加签
 // 	but_delete: "", //作废
 // 	but_dyWfId: "", //动态工作流
 // 	but_consume: "", //消费记录
 // 	processFlag: "" //流程处理标识（业务处理增强）
 // },
-->
