// import Vue from "vue";

// 数字转千分符, v-num2ths="[数值, 保留小数位]"
import num2Dot from "./num2Dot/num2Dot.js";
// Vue.directive("szd-dot", num2Dot);

// 按钮权限 v-szd-but="[*.*.*]"
import authBut from "./permission/permiAuthBut.js";

// import formAttrConf from "./formAttrConfig/formAttrConf.js"
// Vue.directive('szd-comp-conf', formAttrConf)

// 数字字母校验及转换
// v-szd-validate="[source, filed, type]"
// source源数据对象, filed-v-model的key, type-验证类型
import validate from "./inputValidate/validate.js";
// Vue.directive("szd-validate", validate);

// 弹窗大小设置, 可拖动位置, 可拖动大小
import dialog from "./dialog/dialog.js";

// 计算div高度
import calcHeight from "./calcHeight/calcHeight.js";
// Vue.directive("szd-height", calcHeight);

import tableResize from "./tableResize/tableResize";
// Vue.directive("table-resize", tableResize);

const installDirective = (app) => {
  app.directive("szd-dialog", dialog);
  app.directive("szd-but", authBut);
  app.directive("szd-height", calcHeight);
  app.directive("table-resize", tableResize);
  app.directive("szd-dot", num2Dot);
  app.directive("szd-validate", validate);
};

export default installDirective;
