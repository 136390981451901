// 数字转千分符
import { ElMessage } from "element-plus";
import { translateLang } from "@/common/tools/commonTool";
// 数字后面加0
function addZeroStr(_value, floatNum) {
  let value = _value;
  if (Number.isNaN(parseFloat(_value))) {
    value = 0;
  }
  if (floatNum == 0) {
    return parseInt(value);
  }
  const valueArr = `${value || 0}`.split(".");
  let dotNum = valueArr[1] ? `${valueArr[1]}` : "";
  const diff = floatNum - dotNum.length;
  // 不够位数则补充0
  if (diff > 0) {
    for (let i = 0; i < diff; i++) {
      dotNum += "0";
    }
    return `${valueArr[0] || 0}.${dotNum}`;
  } else {
    // 超过位数则截取
    const dotNum2 = lenNum(dotNum, floatNum);
    return `${valueArr[0]}.${dotNum2}`;
  }
}
// 截取数字固定长度
function lenNum(num, len) {
  const lenNumArr = `${num}`.split("");
  const lenResArr = lenNumArr.slice(0, len);
  return lenResArr.join("");
}

// 加逗号分割
export function addCommas(value) {
  const parts = value.toString().split(".");
  const intNum = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (parts[1]) {
    return `${intNum}.${parts[1]}`;
  } else {
    return `${intNum}`;
  }
}

export default {
  updated(el, binding, vnode) {
    // 只操作显示, 不操作源数据
    const sourceData = binding.value[0];
    const sourceKey = binding.value[1];
    const floatNum = binding.value[2] == undefined ? 2 : binding.value[2];
    const inp = el.querySelector("input") || el;
    const _this = vnode.context;

    if (inp.tagName == "INPUT") {
      if (inp.isFocus) return;
      binding.instance.$nextTick(() => {
        const value = inp.value;
        const _value = addZeroStr(value, floatNum);
        // 设置源数据会引起input值改变, 所以展示值要在改变后再复制
        const commaNum = addCommas(_value);
        inp.value = commaNum;
      });
    } else {
      const value = sourceData[sourceKey];
      const _value = addZeroStr(value, floatNum);
      el.innerHTML = addCommas(_value, floatNum);
    }
  },
  mounted(el, binding, vnode) {
    // 只设置value的显示, 不改变源数据
    const sourceData = binding.value[0];
    const sourceKey = binding.value[1];
    const floatNum = binding.value[2] == undefined ? 2 : binding.value[2];
    const inp = el.querySelector("input") || el;
    const _this = vnode.context;
    if (inp.tagName == "INPUT") {
      inp.onfocus = e => {
        inp.isFocus = true;
        if (parseFloat(inp.value) == 0) {
          inp.value = "";
        } else {
          inp.value = inp.value.replaceAll(",", "");
        }
      };
      inp.onblur = e => {
        // 输入的值不符合, 要替换
        inp.isFocus = false;
        const reg = /^[-]?\d*(\.\d*)?$/;
        if (!reg.test(inp.value)) {
          ElMessage.error(translateLang("只能输入数字"));
          // 替换非数字,-, .
          const _value = inp.value.replace(/[^0-9|\.|-]/g, "");
          // vnode.context.$set(sourceData, sourceKey, _value);
          sourceData[sourceKey] = _value;
          return;
        }
        // 小数位填满
        const _value = addZeroStr(inp.value, floatNum);
        // vnode.context.$set(sourceData, sourceKey, _value)
        sourceData[sourceKey] = _value;
        // 设置源数据会引起input值改变, 所以展示值要在改变后再复制
        binding.instance.$nextTick(() => {
          const val = addCommas(_value, floatNum);
          inp.value = val;
        });
      };
    } else {
      const value = sourceData[sourceKey];
      const _value = addZeroStr(value, floatNum);
      el.innerHTML = addCommas(_value, floatNum);
    }
  }
};
